<template>
  <!-- 营销中心 -->
  <div class="marke">
    <el-container style="height: 100%; width: 100%">
      <el-aside width="250px">
        <el-menu @select="handleSelect" :default-active="isSelect" :unique-opened="true">
          <el-submenu index="1" class="el_menu_left" v-if="all_power.is_Account || all_power.is_customer || all_power.is_service">
            <template slot="title">
              <!--<img-->
              <!--style="margin-right: 5px"-->
              <!--src="../../assets/img/Marketing_kehuguanli.png"-->
              <!--alt=""-->
              <!--/>-->
              <i class="iconfont icon-Dosm_kehuguanli"></i>
              客户管理
            </template>
            <el-menu-item index="1-1" v-if="all_power.is_Account"> 跟单待办事项 </el-menu-item>
            <el-menu-item index="1-8" v-if="all_power.is_customer"> 意向客户管理 </el-menu-item>
            <el-menu-item index="1-3" v-if="all_power.is_customer"> 成交客户管理 </el-menu-item>
            <!--<el-menu-item index="1-4" v-if="all_power.is_service">-->
            <!--客户分配管理-->
            <!--</el-menu-item>-->
            <!--<el-menu-item index="1-5" >-->
            <!--交接客户-->
            <!--</el-menu-item>-->
            <el-menu-item index="1-6"> 公海 </el-menu-item>
          </el-submenu>
          <el-submenu index="3" class="el_menu_left" v-if="all_power.is_order_list || all_power.is_drawback">
            <template slot="title">
              <i class="iconfont icon-DOMS_dingdanguanlimingxi"></i>
              订单管理明细</template
            >
            <el-menu-item index="3-1" v-if="all_power.is_order_list"> 订单明细 </el-menu-item>
            <el-menu-item index="3-2" v-if="all_power.is_drawback"> 退款订单 </el-menu-item>
          </el-submenu>
          <el-submenu index="4" class="el_menu_left" v-if="all_power.is_achievement">
            <template slot="title">
              <i class="iconfont icon-DOMS_yejimingxi"></i>
              业绩明细</template
            >
            <el-menu-item index="4-1" v-if="all_power.is_achievement"> 到账列表 </el-menu-item>
          </el-submenu>
          <el-submenu index="6" class="el_menu_left" v-if="all_power.is_Promotion">
            <template slot="title">
              <i class="iconfont icon-DOMS_cuxiaoguanli"></i>
              促销管理</template
            >
            <el-menu-item index="6-1" v-if="all_power.is_Promotion"> 促销订单 </el-menu-item>
          </el-submenu>
          <el-submenu index="10" class="el_menu_left" v-if="all_power.is_PK_list">
            <template slot="title">
              <i class="iconfont icon-DOMS_PKzhanyi"></i>
              <span>PK战役</span></template
            >
            <el-menu-item index="10-1" v-if="all_power.is_PK_list">
              <span>PK榜</span>
            </el-menu-item>
            <!--<el-menu-item index="10-2">-->
            <!--<span>战役管理</span>-->
            <!--</el-menu-item>-->
            <!--<el-menu-item index="10-3" v-if="all_power.is_Historical_2">-->
            <!--<span>战役历史数据</span>-->
            <!--</el-menu-item>-->
            <!--<el-menu-item index="10-4" v-if="all_power.is_configuration">-->
            <!--<span>战役配置</span>-->
            <!--</el-menu-item>-->
          </el-submenu>
          <el-submenu index="7" class="el_menu_left" v-if="all_power.is_Poster || all_power.is_Promoting">
            <template slot="title">
              <i class="iconfont icon-DOMS_rongyuguanli"></i>
              <span>荣誉管理</span></template
            >
            <el-menu-item index="7-1" v-if="all_power.is_Poster">
              <span>到账播报</span>
            </el-menu-item>
            <el-menu-item index="7-2" v-if="all_power.is_Promoting">
              <span>推优海报</span>
            </el-menu-item>
          </el-submenu>
          <!--<el-submenu-->
          <!--index="8"-->
          <!--class="el_menu_left"-->
          <!--v-if="all_power.is_quit || all_power.is_fp"-->
          <!--&gt;-->
          <!--<template slot="title">-->
          <!--<i-->
          <!--class="iconfont icon-tree"-->
          <!--style="margin-right: 5px; color: #3a85fc"-->
          <!--&gt;</i>-->
          <!--<span> 分配管理 </span></template-->
          <!--&gt;-->
          <!--<el-menu-item index="8-1" v-if="all_power.is_quit">-->
          <!--离职客户分配-->
          <!--</el-menu-item>-->
          <!--<el-menu-item index="8-2" v-if="all_power.is_fp">-->
          <!--老客户分配管理-->
          <!--</el-menu-item>-->
          <!--</el-submenu>-->
          <el-submenu
            index="9"
            class="el_menu_left"
            v-if="all_power.is_prodect_analysis || all_power.is_contrast_analysis || all_power.is_achievement_analysis"
          >
            <template slot="title">
              <i class="iconfont icon-Dosm_shujufenxi"></i>
              <span>数据分析</span></template
            >
            <!-- <el-menu-item index="9-1">
              <span>当月数据</span>
            </el-menu-item> -->
            <el-menu-item index="9-2" v-if="all_power.is_achievement_analysis">
              <span>业绩分析</span>
            </el-menu-item>
            <el-menu-item index="9-3" v-if="all_power.is_contrast_analysis">
              <span>对比分析</span>
            </el-menu-item>
            <el-menu-item index="9-4" v-if="all_power.is_prodect_analysis">
              <span>产品分析</span>
            </el-menu-item>
            <el-menu-item index="9-5" v-if="all_power.is_visit_analysis">
              <span>客户面访分析</span>
            </el-menu-item>
            <el-menu-item index="9-6" v-if="all_power.is_DailyArrival">
              <span>日到账分析</span>
            </el-menu-item>
            <el-menu-item index="9-7" v-if="all_power.is_Dailycustomer">
              <span>日新增客户</span>
            </el-menu-item>
            <el-menu-item index="9-8">
              <span>客户服务数统计</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <router-view></router-view>
    </el-container>
  </div>
</template>

<script>
import customer from "./customer";
import Account from "./Account";
export default {
  components: {
    Customer: customer,
    Account: Account,
  },
  data() {
    return {
      isSelect: "",
      roleList: {},
      openeds: [],
      all_power: {
        is_Account: false,
        is_customer: false,
        is_achievement: false,
        is_order_list: false,
        is_drawback: false,
        is_Promotion: false,
        is_quit: false,
        is_fp: false,
        is_Poster: false, //到账播报
        is_PK_list: false, //PK榜
        is_Promoting: false, //推优播报
        is_prodect_analysis: false, //产品分析
        is_contrast_analysis: false, //对比分析
        is_achievement_analysis: false, //业绩分析
        is_Historical_2: false,
        is_configuration: false,
        is_visit_analysis: false,
        is_DailyArrival: false,
        is_Dailycustomer: false,
      },
    };
  },
  created() {
    let token = JSON.parse(localStorage.getItem("token"));
    for (let i in token.role_permissions) {
      if (token.role_permissions[i].permissions == "Marketing") {
        for (let v in token.role_permissions[i].data) {
          if (token.role_permissions[i].data[v].permissions == "Marketing_Account") {
            this.all_power.is_Account = true;
          }
          if (token.role_permissions[i].data[v].permissions == "service_customer") {
            this.all_power.is_service = true;
          }
          if (token.role_permissions[i].data[v].permissions == "Marketing_customer") {
            this.all_power.is_customer = true;
          }
          if (token.role_permissions[i].data[v].permissions == "achievement_list") {
            this.all_power.is_achievement = true;
          }
          if (token.role_permissions[i].data[v].permissions == "system_order_list") {
            //订单列表
            this.all_power.is_order_list = true;
          }
          if (token.role_permissions[i].data[v].permissions == "system_drawback") {
            //退款列表
            this.all_power.is_drawback = true;
          }
          if (token.role_permissions[i].data[v].permissions == "system_Promotion") {
            //促销列表
            this.all_power.is_Promotion = true;
          }

          if (token.role_permissions[i].data[v].permissions == "Poster_Arrival_edit") {
            this.all_power.is_Poster = true;
          }
          if (token.role_permissions[i].data[v].permissions == "Poster_list") {
            this.all_power.is_Promoting = true;
          }
          if (token.role_permissions[i].data[v].permissions == "Historical_data2") {
            this.all_power.is_Historical_2 = true;
          }
          if (token.role_permissions[i].data[v].permissions == "Historical_data2") {
            this.all_power.is_Historical_2 = true;
          }
          if (token.role_permissions[i].data[v].permissions == "configuration") {
            this.all_power.is_configuration = true;
          }
          if (token.role_permissions[i].data[v].permissions == "customer_visit") {
            this.all_power.is_visit_analysis = true;
          }
          if (token.role_permissions[i].data[v].permissions == "DailyArrival") {
            this.all_power.is_DailyArrival = true;
          }
          if (token.role_permissions[i].data[v].permissions == "Dailycustomer") {
            this.all_power.is_Dailycustomer = true;
          }
        }
      }
    }
    for (let i in token.role) {
      if (token.role[i].permissions == "quit_list") {
        //分配列表
        this.all_power.is_quit = true;
      }
      if (token.role[i].permissions == "chief_human_list") {
        this.all_power.is_fp = true;
      }
      if (token.role[i].permissions == "PK_list") {
        //PK榜
        this.all_power.is_PK_list = true;
      }
      if (token.role[i].permissions == "prodect_analysis") {
        this.all_power.is_prodect_analysis = true;
      }
      if (token.role[i].permissions == "achievement_analysis") {
        this.all_power.is_achievement_analysis = true;
      }
      if (token.role[i].permissions == "contrast_analysis") {
        this.all_power.is_contrast_analysis = true;
      }
    }
    if (localStorage.getItem("isSelect")) {
      this.isSelect = localStorage.getItem("isSelect");
      if (this.$route.fullPath == "/Marketing/customer/detail") {
        return;
      }
      if (this.$route.fullPath == "/Marketing/customer/order") {
        return;
      }
      if (this.$route.fullPath == "/Marketing/customer/achievement") {
        return;
      }
      if (this.$route.fullPath == "/Marketing/order_list/order_detail") {
        return;
      }
      this.handleSelect(this.isSelect);
    } else {
      this.handleSelect("1-1");
    }
    if (localStorage.getItem("token")) {
      let roleList = JSON.parse(localStorage.getItem("token")).role_permissions;
      roleList.forEach((item) => {
        if (item.permissions == "Marketing") {
          this.roleList = item;
        }
      });
    }
  },
  watch: {
    $route(to, from) {
      if (localStorage.getItem("add_excellent")) {
        this.$router.push("/Marketing/play_bill_copy");
      } else {
        this.$router.push(to.path);
      }
      if (from.fullPath == "/Marketing/achievement_center" && to.fullPath == "/Marketing/Account") {
        this.handleSelect("1-1");
      }
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      localStorage.setItem("isSelect", key);
      console.log('key',key)
      this.isSelect = localStorage.getItem("isSelect");
      if (key == "1-1") {
        this.$router.push("/Marketing/Account");
      }
      if (key == "1-8") {
        this.$router.push("/Marketing/customer");
      }
      if (key == "1-3") {
        this.$router.push("/Marketing/Deal_customer");
      }
      if (key == "1-4") {
        this.$router.push("/Marketing/service");
      }
      if (key == "1-5") {
        this.$router.push("/Marketing/Handover_customer");
      }
      if (key == "1-6") {
        this.$router.push("/Marketing/international_waters");
      }
      if (key == "3-1") {
        this.$router.push("/Marketing/order_list");
      }
      if (key == "3-2") {
        this.$router.push("/Marketing/drawback");
      }
      if (key == "4-1") {
        this.$router.push("/Marketing/achievement_center");
      }
      if (key == "6-1") {
        this.$router.push("/Marketing/Promotion");
      }
      if (key == "7-1") {
        this.$router.push("/Marketing/play_bill");
      }
      if (key == "7-2") {
        this.$router.push("/Marketing/play_bill_copy");
      }
      if (key == "8-1") {
        this.$router.push("/Marketing/quit");
      }
      if (key == "8-2") {
        this.$router.push("/Marketing/Customer_assignment");
      }
      if (key == "10-1") {
        this.$router.push("/Marketing/Pk_list");
      }
      if (key == "10-2") {
        this.$router.push("/Marketing/management");
      }
      if (key == "10-3") {
        this.$router.push("/Marketing/Historical_data2");
      }
      if (key == "10-4") {
        this.$router.push("/Marketing/configuration");
      }
      if (key == "9-1") {
        this.$router.push("/Marketing/Month_data");
      }
      if (key == "9-2") {
        this.$router.push("/Marketing/Historical_data");
      }
      if (key == "9-3") {
        this.$router.push("/Marketing/Analysis_data");
      }
      if (key == "9-4") {
        this.$router.push("/Marketing/prodect_data");
      }
      if (key == "9-5") {
        this.$router.push("/Marketing/visit_analysis");
      }
      if (key == "9-6") {
        this.$router.push("/Marketing/DailyArrival");
      }
      if (key == "9-7") {
        this.$router.push("/Marketing/Dailycustomer");
      }
      if (key == "9-8") {
        this.$router.push("/Marketing/customerSerivces");
      }
    },
  },
};
</script>

<style lang="scss">
.marke {
  height: 100%;
  width: 100%;
  display: flex;
  .el-aside {
    height: 100%;
    background: #fff;
  }
}
.el_menu_left .iconfont {
  font-size: 20px;
  color: #4392ff;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el_menu_left .el-submenu__title {
  display: flex;
  align-items: center;
}
</style>
