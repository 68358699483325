import request from '@/utils/axios.tool';
//获取全部部门
export const selectCompanyAll = (data) => {
  return request.post(`/roleapi/companys/selectCompanyAll`, data)
}
//根据上一级获取下一级
export const select = (data) => {
  return request.post(`/roleapi/company/select`, data)
}
//员工列表
export const userList = (data) => {
  return request.post(`/roleapi/user/userList`, data)
}
// 获取上级  面包屑
export const getAncestorsAndSelf = (data) => {
  return request.post(`/roleapi/companys/getAncestorsAndSelf`, data)
}
//添加部门
export const add = (data) => {
  return request.post(`/roleapi/company/add`, data)
}
//更改部门
export const update = (data) => {
  return request.post(`/roleapi/company/update`, data)
}
//删除部门
export const deleteCompany = (data) => {
  return request.post(`/roleapi/company/deleteCompany`, data)
}

//获取所有职位
export const positionList = (data) => {
  return request.get(`/roleapi/user/positionList`, data)
}
//新获取所有职位
export const newPositionList = (data) => {
  return request.get(`/roleapi/user/newPositionList`, data)
}

//搜索
export const searchCompany = (data) => {
  return request.post(`/roleapi/company/searchCompany
  `, data)
}
//添加用户选择部门的时候
export const addUserSelectCompany = (data) => {
  return request.post(`/roleapi/companys/addUserSelectCompany
  `, data)
}
//添加用户选择部门的时候--获取面包屑
export const addUserGetAncestorsAndSelf = (data) => {
  return request.post(`/roleapi/companys/addUserGetAncestorsAndSelf
  `, data)
}
// 添加员工的时候—查询角色
export const selectRole = (data) => {
  return request.post(`/roleapi/role/selectRole
  `, data)
}
// 添加人员
export const user_add = (data) => {
  return request.post(`/roleapi/user/add
  `, data)
}
//人员详情
export const userDetail = (data) => {
  return request.post(`/roleapi/user/userDetail
  `, data)
}
//修改人员
export const edit = (data) => {
  return request.post(`/roleapi/user/edit
  `, data)
}
//删除人员
export const del = (data) => {
  return request.post(`/roleapi/user/del
  `, data)
}
// 判断是否编辑部门/添加员工/添加下级
export const checkCompanyOperate = (data) => {
  return request.post(`/roleapi/company/checkCompanyOperate
  `, data)
}
// 判断搜索员工是否可以修改
export const checkUserOperate = (data) => {
  return request.post(`/roleapi/company/checkUserOperate
  `, data)
}
// 部门荣誉列表
export const honorList = (data) => {
  return request.post(`/roleapi/company/honorList
  `, data)
}

// 添加部门荣誉
export const addHonor = (data) => {
  return request.post(`/roleapi/company/addHonor
  `, data)
}
// 删除部门荣誉
export const delHonor = (data) => {
  return request.post(`/roleapi/company/delHonor
  `, data)
}
// 修改部门荣誉
export const editHonor = (data) => {
  return request.post(`/roleapi/company/editHonor
  `, data)
}
// 个人荣誉列表
export const human_honorList = (data) => {
  return request.post(`/roleapi/user/honorList
  `, data)
}
//个人新增荣誉
export const human_addHonor = (data) => {
  return request.post(`/roleapi/user/addHonor
  `, data)
}
// 个人删除荣誉
export const human_delHonor = (data) => {
  return request.post(`/roleapi/user/delHonor
  `, data)
}

//个人修改荣誉
export const human_editHonor = (data) => {
  return request.post(`/roleapi/user/editHonor
  `, data)
}

//重置密码
export const password = (data) => {
    return request.post(`/roleapi/user/resetUserPassword`, data)
}


//修改用户所属部门时判断是否跨事业部
export const checkDivision = (data) => {
    return request.post(`/roleapi/user/checkDivision`, data)
}


//日到账数据分析
export const dailyArrivalAnalysis = (data) => {
    return request.get(`/roleapi/statistics/dailyArrivalAnalysis`, data)
}

//日客户
export const dailyCustomerAnalysis = (data) => {
    return request.get(`/roleapi/statistics/dailyCustomerAnalysis`, data)
}
//客户服务数统计
export const customerServiceCount = (data) => {
  return request.get(`/roleapi/statistics/customerServiceCount`, data)
}
