<template>
  <div class="dzgl">
    <el-main>
      <div class="finace_main_header">
        <div class="main_header">
          <!-- <template slot-scope="scope" > -->
          <div class="main_header_line">
            <div class="line_name">部门：</div>
            <el-cascader
              placeholder="请选择部门名称"
              :options="options"
              ref="myCascader"
              @change="handleClick"
              :key="isResouceShow"
              clearable
              :props="{ checkStrictly: true }"
            ></el-cascader>
          </div>
          <div class="main_header_line" v-if="isPut">
            <div class="line_name">到账时间：</div>
            <div class="line_input">
              <!-- <el-date-picker
                :editable="iseditable"
                class="date_time"
                prefix-icon="saa"
                style="width: 40%; height: 32px; min-width: 96px"
                v-model="search_start_time"
                type="date"
                :picker-options="pickerOptions"
                @focus="handleFocus(1)"
                @change="handleTime($event, 1)"
                placeholder="开始时间"
              >
              </el-date-picker>
              <i>-</i>
              <el-date-picker
                :editable="iseditable"
                prefix-icon="saa"
                class="date_time"
                v-model="search_end_time"
                style="width: 40%; height: 32px; min-width: 96px"
                type="date"
                :picker-options="pickerOptions"
                @focus="handleFocus(2)"
                @change="handleTime($event, 2)"
                placeholder="结束时间"
              >
              </el-date-picker> -->
              <el-date-picker
                prefix-icon="saa"
                v-model="search_start_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="main_header_line" v-if="isPut">
            <div class="line_name">分单人：</div>
            <el-autocomplete
              class="inline-input"
              v-model="policy_name"
              :fetch-suggestions="querySearch"
              placeholder="请输入姓名选择分单人"
              :trigger-on-focus="false"
              @select="Selectpolicy_name"
            ></el-autocomplete>
          </div>
          <!-- <div class="main_header_line" v-if="isPut">
            <div class="line_name">状态：</div>
            <el-select v-model="status" clearable placeholder="请选择状态">
              <el-option
                v-for="item in statuslist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
        </div>
        <div class="main_header">
          <div class="main_header_line">
            <div class="line_name">提交人：</div>
            <el-input
              v-model="search_name"
              placeholder="请输入姓名"
              style="height: 32px; flex: 1"
            ></el-input>
          </div>
          <div class="main_header_line" v-if="isPut">
            <div class="line_name">到账金额：</div>
            <div class="line_input">
              <el-input
                v-model="search_start_money"
                placeholder="起始金额"
                style="height: 32px; width: 100px"
              ></el-input>
              <i>-</i>
              <el-input
                v-model="search_end_money"
                placeholder="结束金额"
                style="height: 32px; width: 100px"
              ></el-input>
            </div>
          </div>
           <div class="main_header_line" v-if="isPut">
            <div class="line_name">合同编号：</div>
            <el-input
              v-model="contract_number"
              placeholder="请输入合同编号"
              style="height: 32px; flex: 1"
            ></el-input>
          </div>
        </div>
        <div class="main_header">
          <div class="main_header_line">
            <div class="line_name">产品：</div>
            <el-cascader
              clearable
              v-model="search_product_id"
              :options="productList"
              placeholder="请选择产品"
              separator=" - "
            >
            </el-cascader>
          </div>
          <div class="main_header_line" v-if="isPut">
            <div class="line_name">订单号：</div>
            <el-input
              v-model="search_order"
              placeholder="请输入订单号"
              style="height: 32px; flex: 1"
            ></el-input>
          </div>
        </div>
        <div class="main_header">
          <div class="main_header_line" v-if="isPut">
            <div class="line_name">公司名称：</div>
            <el-input
              v-model="search_corporate_name"
              placeholder="请输入公司名称"
              style="height: 32px; flex: 1"
            ></el-input>
          </div>
          <div class="main_header_line" v-if="isPut">
            <div class="line_name">客户电话：</div>
            <el-input
                v-model="search_phone"
                type="number"
                placeholder="请输入客户电话"
                style="height: 32px; flex: 1"
            ></el-input>
          </div>
          <div class="main_header_line" v-if="isPut">
            <div class="line_name">收款方式：</div>
            <el-select
              v-model="collection_id"
              clearable
              placeholder="请选择收款方式"
            >
              <el-option
                v-for="item in collectionList"
                :key="item.id"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>

          <div class="header_buttom">
            <div class="finance_find" @click="handleFind(0)">查询</div>
            <div class="finance_reset" @click="handleReset">重置</div>
            <div class="finance_up" @click="handlePut">
              {{ isPut ? "收起" : "展开" }}
              <i v-if="isPut" class="el-icon-arrow-up"></i>
              <i v-else class="el-icon-arrow-down"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="tkgl_header_search_tab">
        <div
          class="tkgl_header_search_tab_one"
          v-for="(item, index) in status_tab_arr"
          :key="index"
          @click="checkstatus(item.id)"
        >
          <div :class="status_tab == item.id ? 'tab_tit_active' : ''">
            {{ item.name }}
          </div>
          <div :class="status_tab == item.id ? 'tab_active' : ''"></div>
        </div>
      </div>
      <div class="content">
        <div class="export" v-if="this.ability.export_Arrival">
          <div class="export_button" @click="handleExport">
            <i class="el-icon-printer" style="margin-right: 5px"></i>
            导出
          </div>
        </div>
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          style="width: 100%"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column label="订单号" :sort-method="handleSort">
            <template scope="scope">
              <div>
                {{
                  scope.row.order
                    ? scope.row.order.order_number
                      ? scope.row.order.order_number
                      : "--"
                    : "--"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="order.customer.name" label="公司名称">
            <template scope="scope">
              <div>
                {{
                  scope.row.order
                    ? scope.row.order.customer
                      ? scope.row.order.customer.name
                      : "--"
                    : "--"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="order.product_name.product_name"
            label="签约产品"
          >
          </el-table-column>
          <el-table-column width="180" label="业绩归属人">
            <template scope="scope">
              <div>
                <div>
                  <!-- 姓名： -->
                  {{ scope.row.user ? scope.row.user.name : "--" }}
                  <span
                    style="color: red"
                    v-if="scope.row.user && scope.row.user.deleted_at"
                    >【{{ scope.row.user.deleted_at ? "已离职" : "" }}】</span
                  >
                </div>
                <div>
                  <!-- 部门： -->
                  {{
                    scope.row.order_data_branch_one.companys
                      ? scope.row.order_data_branch_one.companys.name
                      : "--"
                  }}
                    <span
                    style="color: red"
                    v-if="scope.row.order_data_branch_one.companys && scope.row.order_data_branch_one.companys.delete==1"
                    >【{{ scope.row.order_data_branch_one.companys.delete==1 ? "已删除" : "" }}】</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
                            prop="user.name"
                            label="提交人">
                        </el-table-column> -->
          <el-table-column
            prop="order.contract_money"
            :sort-method="handleSort_money"
            label="合同金额（元）"
          >
          </el-table-column>
            <el-table-column label="合同编号">
            <template scope="scope">
              {{  scope.row.order ? scope.row.order.contract_number  ? scope.row.order.contract_number  : "--" : '--'}}
            </template>
          </el-table-column>
          <el-table-column
            prop="to_money"
            :sort-method="handleSort_to_money"
            label="到账金额（元）"
          >
          </el-table-column>
          <el-table-column label="分单人">
            <template scope="scope">
              <div class="scope_fd">
                <div
                  class="scope_fd_one"
                  v-show="scope.row.order_data_branch.length > 0"
                  v-for="(item, index) in scope.row.order_data_branch"
                  :key="index"
                >
                  <div>
                    分单人：{{ item.user.name }}
                    <span style="color: red" v-if="item.user.deleted_at"
                      >【{{ item.user.deleted_at ? "已离职" : "" }}】</span
                    >
                  </div>
                  <div>分单金额：{{ item.money }}</div>
                </div>
                <div v-if="JSON.stringify(scope.row.order_data_branch) == '[]'">
                  --
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="收款方式">
            <template scope="scope">
              {{ scope.row.collection ? scope.row.collection : "--" }}
            </template>
          </el-table-column>
          <el-table-column label="到账时间">
            <template scope="scope">
              {{ scope.row.to_time ? scope.row.to_time : "--" }}
            </template>
          </el-table-column>
          <el-table-column prop="status_text" label="状态">
            <template scope="scope">
              <i
                :class="
                  scope.row.status == 0
                    ? 'wait_status'
                    : scope.row.status == 1
                    ? 'pass_status'
                    : scope.row.status == 2
                    ? 'reject_status'
                    : 'revoke_status'
                "
              ></i>
              {{ scope.row.status_text }}
            </template>
          </el-table-column>
          <el-table-column prop="order_data_log.user.name" label="审核人">
            <template scope="scope">
              {{ scope.row.order_data_log&&scope.row.order_data_log.user?scope.row.order_data_log.user.name:'--' }}
              <br />
              {{ scope.row.order_data_log?scope.row.order_data_log.created_at:'--' }}
            </template>
          </el-table-column>
          <el-table-column
           fixed="right"
            label="操作"
            v-if="this.ability"
            :key="Math.random()"
            width="120"
          >
            <template slot-scope="scope">
              <div class="cz">
                <div
                  @click="handleDetail(scope.row)"
                  class="cz_detail"
                  :style="
                    (ability.change_Arrival && status_tab != 2) ||
                    (ability.del_Arrival && scope.row.status != 1)
                      ? ''
                      : 'border:none'
                  "
                >
                  审批
                </div>
                <el-dropdown
                  @command="handleDetail"
                  v-if="
                    (ability.change_Arrival && status_tab != 2) ||
                    (ability.del_Arrival && scope.row.status != 1)
                  "
                >
                  <div class="pointer">
                    更多
                    <i class="el-icon-arrow-down"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-if="ability.change_Arrival && status_tab != 2"
                      :command="beforCommand('-1', scope.row)"
                      >编辑</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="ability.del_Arrival && scope.row.status != 1"
                      :command="beforCommand('2', scope.row)"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            el-pagination
            layout="total,prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
    <div class="login_mc" v-if="isMc" @click="handleClose_img"></div>
    <div class="img_show" v-if="isImg" @click="handleClose_img">
      <img :src="img_src" alt="" />
    </div>
    <div class="detail" v-if="isDetail">
      <div class="detail_tit">
        <div>
          到账详情
          <i class="el-icon-close" @click="handleClose(1)"></i>
        </div>
      </div>
      <div class="detail_content">
        <div class="order_number">
          订单号：{{ order_detail.order.order_number }}
        </div>
        <div class="detail_content_status">
          状态：{{
            order_detail.status == 0
              ? "待审核"
              : order_detail.status == 1
              ? "通过"
              : order_detail.status == 2
              ? "驳回"
              : "已撤销"
          }}
        </div>
        <div class="detail_content_status" v-if="order_detail.reason">
          财务备注：{{ order_detail.reason }}
        </div>
        <div class="detail_content_box">
          <div class="detail_content_box_flex">
            <div class="detail_content_box_left">
              <div class="box_left_head">
                <div class="box_left_head_L"></div>
                <div class="box_left_head_R">客户信息</div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">公司名称：</div>
                <div class="detail_content_box_line_info">
                  {{ order_detail.order.customer? order_detail.order.customer.name?order_detail.order.customer.name:'' :''}}
                </div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">联系人：</div>
                <div class="detail_content_box_line_info">
                  {{ order_detail.order.my_customer_contacts.contacts }}
                </div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">电话：</div>
                <div class="detail_content_box_line_info">
                  {{ order_detail.order.my_customer_contacts.phone }}
                </div>
              </div>
            </div>
            <div class="detail_content_box_left">
              <div class="box_left_head">
                <div class="box_left_head_L"></div>
                <div class="box_left_head_R">合同信息</div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">合同号：</div>
                <div class="detail_content_box_line_info">
                  {{ order_detail.order.contract_number }}
                </div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">合同金额：</div>
                <div class="detail_content_box_line_info">
                  {{ order_detail.order.contract_money }}
                </div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">产品：</div>
                <div class="detail_content_box_line_info">
                  {{ order_detail.order.product_name.product_name }}
                </div>
              </div>
               <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">本单应收全款金额:</div>
                  <div class="detail_content_box_line_info">
                    {{ order_detail.order.total_amount_receivable }}
                  </div>
                </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">服务起止时间:</div>
                <div class="detail_content_box_line_info">
                    {{ order_detail.order.service_time  }}&nbsp;至&nbsp;{{ order_detail.order.service_time_end}}
                </div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">备注:</div>
                <div class="detail_content_box_line_info">
                  {{ order_detail.order.remarks=='null'?'无':order_detail.order.remarks }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="detail_content_box">
          <div class="detail_content_box_flex">
            <div class="detail_content_box_left">
              <div class="box_left_head">
                <div class="box_left_head_L"></div>
                <div class="box_left_head_R">到账信息</div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">本次实收金额：</div>
                <div class="detail_content_box_line_info">
                  {{ order_detail.to_money }}
                </div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">到账时间：</div>
                <el-date-picker
                  :editable="iseditable"
                  v-if="command == -1"
                  class="datetime_picker"
                  v-model="order_detail.to_time"
                  type="datetime"
                  :picker-options="pickerOptions"
                  @focus="handleFocus(3)"
                  @change="handleTime($event, 3)"
                >
                </el-date-picker>
                <div class="detail_content_box_line_info" v-if="command != -1">
                  {{ order_detail.to_time ? order_detail.to_time : "" }}
                </div>
                <!-- <div
                  class="detail_content_box_line_info"
                  v-if="!order_detail.to_time"
                >
                  --
                </div> -->
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">业绩时间：</div>
                <el-date-picker
                  :editable="iseditable"
                  v-if="command == -1"
                  class="datetime_picker"
                  v-model="order_detail.order_data_branch_one.achievement_time"
                  type="date"
                  :picker-options="pickerOptions"
                  @focus="handleFocus(4)"
                  @change="handleTime($event, 4)"
                  style="margin: 5px 0"
                >
                </el-date-picker>
                <div class="detail_content_box_line_info" v-if="command != -1">
                  {{
                    order_detail.order_data_branch_one.achievement_time
                      ? order_detail.order_data_branch_one.achievement_time
                      : ""
                  }}
                </div>
              </div>

              <div class="detail_content_box_line detail_content_box_line_img">
                <div class="detail_content_box_line_tit">协议信息：</div>
                <div class="detail_content_box_line_info">
                  <!-- <img
                    @click="handleImg(item.file_url)"
                    :src="item.file_url"
                    alt=""
                    v-for="(item, index) in order_detail.order.contract_file"
                    :key="index"
                  /> -->
                  <el-image
                    v-for="(item, index) in order_detail.order
                      .contract_file_url"
                    :key="index"
                    style="width: 60px; height: 60px; margin-right: 10px"
                    :src="item"
                    :preview-src-list="order_detail.order.contract_file_url"
                  >
                  </el-image>
                </div>
              </div>

              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">提交人：</div>
                <div class="detail_content_box_line_info">
                  {{ order_detail.user.name }}【{{
                    order_detail.order_data_branch_one.companys.name
                  }}】
                </div>
              </div>
            </div>
            <div class="detail_content_box_left">
              <div class="box_left_head"></div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">收款方式：</div>
                <el-select
                  v-model="order_detail.collection_text"
                  placeholder="请选择收款方式"
                  clearable
                  v-if="command == -1 && order_detail.collection_text"
                >
                  <el-option
                    v-for="item in collectionList"
                    :key="item.id"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div class="detail_content_box_line_info" v-else>
                  {{
                    order_detail.collection_text
                      ? order_detail.collection_text
                      : ""
                  }}
                </div>
                <div
                  class="detail_content_box_line_info"
                  v-if="!order_detail.collection_text"
                >
                  --
                </div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">到账类型：</div>
                <div class="detail_content_box_line_info">
                  {{
                    order_detail.type == 1
                      ? "定金"
                      : order_detail.type == 2
                      ? "尾款"
                      : "全款"
                  }}
                </div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">提交人电话：</div>
                <div class="detail_content_box_line_info">
                  {{ order_detail.user.phone }}
                </div>
              </div>
              <div
                class="detail_content_box_line detail_content_box_line_img"
                v-if="order_detail.collection_file"
              >
                <div class="detail_content_box_line_tit">收款凭证：</div>
                <div class="detail_content_box_line_info">
                  <!-- <img
                    @click="handleImg(item.file_url)"
                    :src="item.file_url"
                    alt=""
                    v-for="(item, index) in order_detail.collection_file"
                    :key="index"
                  /> -->
                  <el-image
                    v-for="(item, index) in order_detail.collection_file_url"
                    :key="index"
                    style="width: 60px; height: 60px; margin-right: 10px"
                    :src="item"
                    :preview-src-list="order_detail.collection_file_url"
                  >
                  </el-image>
                </div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">归属部门：</div>
                <div class="detail_content_box_line_info" v-if="command != -1">
                  {{ order_detail.order_data_branch_one.companys.name }}
                </div>
                <el-cascader
                  v-if="command == -1"
                  placeholder="请选择部门名称"
                  :options="options"
                  ref="myCascader"
                  @change="handleClick"
                  :key="isResouceShow"
                  clearable
                  v-model="company_edit"
                  :props="{ checkStrictly: true }"
                ></el-cascader>
              </div>
            </div>
          </div>
          <div class="detail_content_box_noflex">
            <div class="detail_content_box_remarks_tit">备注：</div>
            <div class="detail_content_box_remarks_info">
              {{ order_detail.remarks == "null" ? "无" : order_detail.remarks }}
            </div>
          </div>
        </div>

        <div class="detail_content_box">
          <div class="detail_content_box_flex">
            <div class="detail_content_box_left" style="width: 100%">
              <div class="box_left_head">
                <div class="box_left_head_L"></div>
                <div class="box_left_head_R">分单信息</div>
              </div>
              <div
                class="no_body"
                v-if="order_detail.order_data_branch.length == 0"
              >
                暂无分单信息
              </div>
              <div
                class="detail_content_box_line"
                v-for="(item, index) in order_detail.order_data_branch"
                :key="index"
                style="display: flex;justify-content: space-between;align-items: center"
              >
                <div style="display: flex;align-items: center;width: 20%" >
                  <div class="detail_content_box_line_tit">分单人：</div>
                  <div class="detail_content_box_line_info">
                    {{ item.user.name }}
                  </div>
                </div>
                <div style="display: flex;align-items: center">
                  <div class="detail_content_box_line_tit">分单金额：</div>
                  <div class="detail_content_box_line_info">{{ item.money }}</div>
                </div>
                <div class="detail_content_box_line">
                  <div class="detail_content_box_line_tit">归属部门：</div>
                  <div class="detail_content_box_line_info" v-if="command != -1">
                    {{ order_detail.order_data_branch_one.companys.name }}
                  </div>
                  <el-cascader
                          v-if="command == -1"
                          placeholder="请选择部门名称"
                          :options="options"
                          ref="myCascader"
                          @change="handleClick($event,item.id)"
                          :key="isResouceShow"
                          clearable
                          :value="item.company_id"
                          :props="{ checkStrictly: true }"
                  ></el-cascader>
                </div>
              </div>
            </div>
            <!--<div class="detail_content_box_left">-->
              <!--<div class="box_left_head"></div>-->
              <!--<div-->
                <!--class="detail_content_box_line"-->
                <!--v-for="(item, index) in order_detail.order_data_branch"-->
                <!--:key="index"-->
              <!--&gt;-->

              <!--</div>-->
            <!--</div>-->
          </div>
        </div>
        <div class="detail_content_box">
          <div class="detail_content_box_flex">
            <div class="detail_content_box_left">
              <div class="box_left_head">
                <div class="box_left_head_L"></div>
                <div class="box_left_head_R">促销信息</div>
              </div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">促销类型：</div>
                <div class="detail_content_box_line_info">
                  {{ order_detail.promotion.text }}
                </div>
              </div>
            </div>
            <div class="detail_content_box_left">
              <div class="box_left_head"></div>
              <div class="detail_content_box_line">
                <div class="detail_content_box_line_tit">促销成本：</div>
                <div class="detail_content_box_line_info">
                  {{ order_detail.promotion.money }}
                </div>
              </div>
            </div>
          </div>
          <div class="detail_content_box_noflex">
            <div class="detail_content_box_remarks_tit">申请说明：</div>
            <div class="detail_content_box_remarks_info">
              {{
                order_detail.promotion.apply_explain
                  ? order_detail.promotion.apply_explain
                  : "----"
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="detail_old">
        <div class="detail_old_tit">
          <div style="display: flex; align-items: center">
            <div class="circular"></div>
            <div>历史到账信息</div>
          </div>
          <div>
            总金额：<span style="color: #3a85fc; font-weight: bold"
              >￥{{ order_detail.history_money_all }}</span
            >
          </div>
        </div>
        <div class="old_line_box">
          <div class="orde_history" v-if="order_detail.history.length == 0">
            暂无历史到账信息
          </div>
          <div class="old_line_header" v-if="order_detail.history.length > 0">
            <div v-for="(item, index) in oldline" :key="index">{{ item }}</div>
          </div>
          <div
            class="old_line_info"
            v-for="(item, index) in order_detail.history"
            :key="index"
          >
            <!-- <div>{{item.order.order_number}}</div> -->
            <div>
              {{ item.type == 1 ? "定金" : item.type == 2 ? "尾款" : "全款" }}
            </div>
            <div>{{ item.to_money }}</div>
            <div>
              {{
                item.collection_id == 1
                  ? "微信"
                  : item.collection_id == 2
                  ? "支付宝"
                  : item.collection_id == 3
                  ? "银行卡"
                  : item.collection_id == 4
                  ? "对公账户"
                  : item.collection_id == 5
                  ? "收钱吧"
                  : item.collection_id == 6
                  ? "银行私账"
                  : item.collection_id == 7
                  ? "POS机"
                  : item.collection_id == 8
                  ? "现金"
                  : "--"
              }}
            </div>
            <div>{{ item.to_time ? item.to_time : "--" }}</div>
            <div>
              <div
                class="fendan"
                v-for="itm in item.order_data_branch"
                :key="itm.name"
              >
                {{ itm.user.name }}
              </div>
            </div>
            <div>
              <div
                class="fendan"
                v-for="(attr, ix) in item.order_data_branch"
                :key="ix"
              >
                {{ attr.money == "----" ? "----" : "￥" + attr.money }}
              </div>
            </div>
            <div>{{ order_detail.promotion.text }}</div>
            <div>{{ order_detail.promotion.money }}</div>
            <div>
              {{
                item.status == 0
                  ? "待审核"
                  : item.status == 1
                  ? "通过"
                  : item.status == 2
                  ? "驳回"
                  : "撤销"
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="detail_buttom">
        <div class="reject" v-if="command == 0" @click="handleReject(2)">
          驳回
        </div>
        <div class="pass" v-if="command == 0" @click="handleReject(1)">
          通过
        </div>
        <div class="revoke" v-if="command == 1" @click="handleReject(3)">
          撤销
        </div>
        <div class="reject" v-if="command == -1" @click="handleClose(1)">
          取消
        </div>
        <div class="pass" v-if="command == -1" @click="handleEdit">保存</div>
      </div>
    </div>
    <div class="reject_box" v-if="isRejext">
      <div class="reject_box_header">
        <div>
          {{ isRejext_text }}
          <i class="el-icon-close" @click="handleClose(2)"></i>
        </div>
      </div>
      <div class="reject_box_tlt">{{ status_type == 1 ? "备注" : "原因" }}</div>
      <div class="reject_box_textarea">
        <el-input
          type="textarea"
          resize="none"
          :rows="2"
          placeholder="请输入内容"
          v-model="textarea"
        >
        </el-input>
      </div>
      <div class="reject_box_buttom">
        <div @click="handleClose(2)">取消</div>
        <div @click="handleOk">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  orderDatalist,
  collectionList,
  productList,
  select,
  orderDetail,
  edit,
  del,
  editStatus,
  selectOrderCompanyWhere,
  userMinCompany,
  orderExport,
  editAchievementAscription,
} from "../../api/User/finance";
import { getUserData } from "../../api/User/customer";

export default {
  data() {
    return {
      iseditable: false,
      isResouceShow: 0,
      userMinlist: [], //用户最小部门id及深度信息
      isMc: false, //蒙层
      isSelect: "1-1",
      isImg: false,
      img_src: "", //图片放大
      isloading: false, //loading
      isPut: true, //收起展开
      input: "1",
      detail_time: "",
      currentPage: 1, //页码
      total: 0, //总条数
      aside_id: 1,
      options: [],
      contract_number:'',//合同编号
      search_company_id: "", //部门名称id
      search_depth: "", //部门深度
      search_corporate_name: "", //公司名称
      search_phone: "", //客户电话
      search_start_time: [], //开始时间
      // search_end_time: "", //结束时间
      search_name: "", //提交人
      search_start_money: "", //金额 开始金额
      search_end_money: "", //金额 结束金额
      search_order: "", //订单号
      command: "0", //按钮  1 编辑
      isDetail: false, //查看详情
      isRejext: false, //驳回撤销
      isRejext_text: "", //驳回撤销文字展示
      status_type: "", //驳回撤销状态码
      textarea: "", //驳回 撤销 原因
      statuslist: [
        { value: "0", label: "待审核" },
        { value: "1", label: "通过" },
        { value: "2", label: "驳回" },
        { value: "3", label: "撤销" },
      ], //筛选状态
      status: "", //状态id
      company_edit: "",
      status_tab: "-1",
      status_tab_arr: [
        { id: "-1", name: "全部" },
        { id: "0", name: "待审核" },
        { id: "1", name: "通过" },
        { id: "2", name: "驳回" },
        { id: "3", name: "撤销" },
      ],
      collectionList: [], //收款方式
      collection_id: "", // 查询 收款方式id
      productList: [], //产品列表
      search_product_id: [], //查询 产品id
      policy_name: "", //查询 分单人
      branch_user_name: "", //查询分单人
      fluterArr: [], //分单人列表
      type_id: "",
      tableData: [],
      oldline: [
        "到账类型",
        "到账金额",
        "收款方式",
        "到账时间",
        "分单人",
        "分单金额",
        "促销类型",
        "促销成本",
        "审核状态",
      ],
      promotion: [
        { value: "1", label: "实物" },
        { value: "2", label: "返现" },
        { value: "3", label: "打折" },
        { value: "4", label: "赠送服务时长" },
        { value: "5", label: "赠送其它服务" },
      ], //促销类型
      order_detail: {}, //详情
      order_to_time: "", //编辑订单详情到账时间
      achievement_time: "", //编辑订单详情业绩时间
      pickerOptions: {},
      ability: {
        del_Arrival: false, //删除到账
        export_Arrival: false, //导出到账列表
        change_Arrival: false, //编辑到账详情
        order_editStatus: false, //财务中心到账审核
        order_detail: false, //财务中心到账详情
        order_list: false, //财务中心到账列表
      },
      role_alldata: {},
    };
  },
  mounted() {
    let roleList = JSON.parse(localStorage.getItem("user")).role_permissions; //权限列表
    let check_id = "";
    if (JSON.parse(localStorage.getItem("check_id"))) {
      check_id = JSON.parse(localStorage.getItem("check_id"));
    }

    for (let i in roleList) {
      if (roleList[i].permissions == "Finance") {
        this.role_alldata = roleList[i];
      }
    }
    // console.log(this.role_alldata);
    roleList.forEach((item) => {
      if (item.id == check_id) {
        if (item.data.length > 0) {
          item.data.forEach((itm) => {
            if (itm.permissions == "del_Arrival") {
              //删除到账
              this.ability.del_Arrival = true;
            }
            if (itm.permissions == "export_Arrival") {
              this.ability.export_Arrival = true;
            }
            if (itm.permissions == "change_Arrival") {
              this.ability.change_Arrival = true;
            }
            if (itm.permissions == "order_editStatus") {
              this.ability.order_editStatus = true;
            }
            if (itm.permissions == "order_detail") {
              this.ability.order_detail = true;
            }
            if (itm.permissions == "order_list") {
              this.ability.order_list = true;
            }
          });
        }
      }
    });
    collectionList().then((res) => {
      //收款方式
      if (res.data.code == 200) {
        this.collectionList = res.data.data;
      }
    });
    productList().then((res) => {
      //产品列表
      if (res.data.code == 200) {
        let list = res.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i].label = list[i].class_name;
          list[i].value = list[i].id;
          list[i].children = list[i].product_name;
          for (let j = 0; j < list[i].product_name.length; j++) {
            list[i].product_name[j].label =
              list[i].product_name[j].product_name;
            list[i].product_name[j].value = list[i].product_name[j].id;
          }
        }
        this.productList = list;
      }
    });

    let str = "";
    for (let i in this.role_alldata.data) {
      if (this.role_alldata.data[i].permissions == "order_list") {
        str = this.role_alldata.data[i].permissions_id_string;
      }
    }
    if (str == "") {
      return;
    }
    let data = {
      permissions_id_string: str,
    };
    this.getOrderdataList(data);
    let minlist = [];
    userMinCompany().then((res) => {
      //用户最小部门id及深度
      if (res.data.code == 200) {
        this.userMinlist = res.data.data;
        minlist = res.data.data;
      }
    });
    selectOrderCompanyWhere().then((res) => {
      if (res.data.code == 200) {
        function dg(list) {
          list.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
            if (item.data) {
              item.children = item.data;
              dg(item.data);
            }
          });
          return list;
        }
        let list = res.data.data;
        dg(list);
        this.options = list;
      }
    });
  },
  methods: {
    getOrderdataList(data) {
      orderDatalist(data).then((res) => {
       
        if (res.data.code == 200) {
          let list = res.data.data.data;
       
          list.forEach((item) => {
            if (!item.order_data_log) {
              //未审核
              let order_data_log = {
                created_at: "",
                user: {
                  name: "----",
                },
              };
              item.order_data_log = order_data_log;
            }
            if (item.status == 0) {
              item.status_text = "待审核";
              let order_data_log = {
                created_at: "",
                user: {
                  name: "----",
                },
              };
              item.order_data_log = order_data_log;
            }
            if (item.status == 1) {
              item.status_text = "通过";
            }
            if (item.status == 2) {
              item.status_text = "驳回";
            }
            if (item.status == 3) {
              item.status_text = "撤销";
            }
            this.collectionList.forEach((itm) => {
              if (itm.id == item.collection_id) {
                item.collection = itm.name;
                return;
              }
            });
          });
          this.total = res.data.data.total;
          this.tableData = list;
        }
      });
    },
    handleSort(obj1, obj2) {
      let num1 = obj1.order.order_number;
      let num2 = obj2.order.order_number;
      return num1 - num2;
    },
    handleSort_money(obj1, obj2) {
      let num1 = obj1.order.contract_money;
      let num2 = obj2.order.contract_money;
      return num1 - num2;
    },
    handleSort_to_money(obj1, obj2) {
      let num1 = obj1.to_money;
      let num2 = obj2.to_money;
      return num1 - num2;
    },
    handleSelect(key, keyPath) {
      this.isSelect = key;
      if (key == "1-1") {
      }
    },
    getwaytime_s(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let strDate = Y + M + D;
      let strDate = Y + M + D + h + m + "00";
      return strDate;
    },
    handleTime(value, i) {
      //时间
      // i   1  开始时间  2 结束时间
      if (i == 1) {
        this.search_start_time = this.getwaytime(value);
      }
      if (i == 2) {
        this.search_end_time = this.getwaytime(value);
      }
      if (i == 3) {
        //编辑修改时间
        this.order_to_time = this.getwaytime_s(value);
      }
      if (i == 4) {
        this.achievement_time = this.getwaytime(value);
      }
    },
    handleFocus(i) {
      //时间选择器事件
      this.pickerOptions = {};
      if (i == 1) {
        if (this.search_end_time == "") {
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() > Date.now() - 8.64e6;
        } else {
          let endtime_s = new Date(this.search_end_time);
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() > Date.parse(endtime_s) - 8.64e6;
        }
      }
      if (i == 2) {
        if (this.search_start_time == "") {
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() > Date.now() - 8.64e6;
        } else {
          let starttime_s = new Date(this.search_start_time);
          this.pickerOptions.disabledDate = (time) =>
            time.getTime() < Date.parse(starttime_s) ||
            time.getTime() > Date.now() - 8.64e6;
        }
      }
      if (i == 3) {
        this.pickerOptions.disabledDate = (time) =>
          time.getTime() > Date.now() - 8.64e6;
      }
      if (i == 4) {
        this.pickerOptions.disabledDate = (time) =>
          time.getTime() > Date.now() - 8.64e6;
      }
    },
    handleClick(value,id) {
        console.log(value)
      if (this.command == -1) {
        //修改业绩到账部门
        if (value.length == 0) {
          this.$message.error("请选择部门");
          ++this.isResouceShow;
          return;
        }
        if(id){
            var data={
                order_data_branch_id: id,
                company_id: value[value.length - 1]
            }
        }else{
            var data={
                order_data_branch_id: this.order_detail.order_data_branch_one.id,
                company_id: value[value.length - 1]
            }
        }


        editAchievementAscription(data).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
              center: true,
            });
          }
        });
      } else {
        if (value.length == 0) {
          this.search_company_id = "";
          ++this.isResouceShow;
          return;
        }
        this.search_depth =
          this.$refs["myCascader"].getCheckedNodes()[0].data.depth;
        this.search_company_id = value[value.length - 1];
      }
       // this.search_company_name = this.$refs['myCascader'].getCheckedNodes()[0].data.name
    },
    handleImg(src) {
      this.img_src = src;
      this.isMc = true;
      this.isDetail = false;
      this.isImg = true;
    },
    handleClose_img() {
      // this.isImg = false;
      // this.isDetail = true;
    },
    checkstatus(e) {
      this.status_tab = e;
      if (e > -1) {
        this.status = e;
      } else {
        this.status = "";
      }
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "order_list") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      let data = {
        search_company_id: "", //部门id
        search_depth: "", //深度
        search_name: "", //提交人姓名
        search_product_id: "", //产品id
        search_corporate_name: "", //公司名称
        search_start_money: "", //开始金额
        search_end_money: "", //结束金额
        search_order: "", //订单号
        search_start_time: "", //开始时间
        search_end_time: "", //结束时间
        collection_id: "", //收款方式
        status: this.status_tab == "-1" ? "" : this.status_tab, //状态
        permissions_id_string: str,
        page: 1,
        branch_user_name: "",
      };
      this.getOrderdataList(data);
    },

    handleExport() {
      //导出事件
      let status_id = ""; //状态
      let collectionid = ""; //收款状态
      let {
        search_company_id,
        search_depth,
        search_name,
        search_product_id,
        search_corporate_name,
        search_start_time,
        search_end_time,
        search_start_money,
        search_end_money,
        search_order,
        collection_id,
        status,
        collectionList,
        statuslist,
        status_tab,
        branch_user_name,
        policy_name,
      } = this;
      if (policy_name == "") {
        branch_user_name = "";
      }
      collectionList.forEach((item) => {
        if (item.name == collection_id) {
          collectionid = item.id;
        }
      });
      if (status_tab == -1) {
        status_id = "";
      } else {
        status_id = status_tab * 1;
      }

      if (isNaN(search_order)) {
        this.$message({
          message: "订单号输入有误",
          type: "error",
          center: true,
        });
        return;
      }
      // if (isNaN(search_end_money * 1) || isNaN(search_start_money * 1)) {
      //   this.$message({
      //     message: "金额输入有误",
      //     type: "error",
      //     center: true,
      //   });
      //   return;
      // }
      // if (search_end_money != "" && search_start_money !== "") {
      //   if (Number(search_end_money) < Number(search_start_money)) {
      //     this.$message({
      //       message: "结束金额不能小于开始金额",
      //       type: "error",
      //       center: true,
      //     });
      //     return;
      //   }
      // }
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "export_Arrival") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      let start_time = "";
      let end_time = "";
      if (this.search_start_time) {
        if (this.search_start_time[0]) {
          start_time = this.getwaytime(this.search_start_time[0]);
        }
        if (this.search_start_time[1]) {
          end_time = this.getwaytime(this.search_start_time[1]);
        }
      }
      let data = {
        search_company_id, //部门id
        search_depth, //深度
        search_name, //提交人姓名
        search_product_id: search_product_id[1], //产品id
        search_corporate_name, //公司名称
        search_start_money, //开始金额
        search_end_money, //结束金额
        search_order, //订单号
        search_start_time: start_time, //开始时间
        search_end_time: end_time, //结束时间
        collection_id: collectionid, //收款方式
        status: this.status_tab == "-1" ? "" : this.status_tab, //状态
        permissions_id_string: str,
        branch_user_name,
      };
      orderExport(data).then((res) => {
        if (res.data.code == 200) {
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = res.data.data;
          link.setAttribute("download", "courman" + ".xls");
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    handlePut() {
      //收起展开
      this.isPut = !this.isPut;
    },
    handeleAside(id) {
      this.aside_id = id;
    },
    querySearch(queryString, cb) {
      this.branch_user_name = queryString;
      getUserData({ user_name: queryString }).then((res) => {
        let list = res.data.data.data;
        list.forEach((item) => {
          item.value = item.user.name + " 【" + item.company.name + "】";
        });
        this.fluterArr = list;
        var restaurants = this.loadAll();
        // var results = queryString
        //   ? restaurants.filter(this.createFilter(queryString))
        //   : restaurants;
        // // // 调用 callback 返回建议列表的数据
        // console.log(results);
        cb(restaurants);
      });
    },
    createFilter(queryString) {
      return (restaurant) => {
        // console.log(restaurant.value.toLowerCase());
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadAll() {
      return this.fluterArr;
    },
    Selectpolicy_name(val) {
      this.branch_user_name = val.user.name;
    },
    handleFind(p) {
      //查询
      // p   0  查询  其他为翻页
      let status_id = ""; //状态
      let collectionid = ""; //收款状态
      let {
        contract_number,
        search_company_id,
        search_depth,
        search_phone,
        search_name,
        search_product_id,
        search_corporate_name,
        search_start_time,
        search_end_time,
        search_start_money,
        search_end_money,
        search_order,
        collection_id,
        status,
        collectionList,
        statuslist,
        branch_user_name,
        policy_name,
      } = this;
      if (policy_name == "") {
        branch_user_name = "";
      }
      let start_time = "";
      let end_time = "";
      // console.log(this.search_start_time);
      if (this.search_start_time) {
        if (this.search_start_time[0]) {
          start_time = this.getwaytime(this.search_start_time[0]);
        }
        if (this.search_start_time[1]) {
          end_time = this.getwaytime(this.search_start_time[1]);
        }
      }

      collectionList.forEach((item) => {
        if (item.name == collection_id) {
          collectionid = item.id;
        }
      });
      statuslist.forEach((item) => {
        if (item.value == status) {
          status_id = item.value;
        }
      });
      if (isNaN(search_order)) {
        this.$message({
          message: "订单号输入有误",
          type: "error",
          center: true,
        });
        return;
      }
      // if (isNaN(search_end_money * 1) || isNaN(search_start_money * 1)) {
      //   this.$message({
      //     message: "金额输入有误",
      //     type: "error",
      //     center: true,
      //   });
      //   return;
      // }
      // if (search_end_money != "" && search_start_money !== "") {
      //   if (Number(search_end_money) < Number(search_start_money)) {
      //     this.$message({
      //       message: "结束金额不能小于开始金额",
      //       type: "error",
      //       center: true,
      //     });
      //     return;
      //   }
      // }
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "order_list") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      let page = 1;
      if (p == 0) {
        page = 1;
        this.currentPage = 1;
      } else {
        page = p;
      }

      // console.log(search_start_time);
      // if (search_start_time) {
      //   search_start_time = search_start_time.split(" ")[0];
      // }
      // if (search_end_time) {
      //   search_end_time = search_end_time.split(" ")[0];
      // }

      let data = {
        search_company_id, //部门id
        search_depth, //深度
        search_name, //提交人姓名
        search_phone,
        search_product_id: search_product_id[1], //产品id
        search_corporate_name, //公司名称
        search_start_money, //开始金额
        search_end_money, //结束金额
        search_order, //订单号
        search_start_time: start_time, //开始时间
        search_end_time: end_time, //结束时间
        collection_id: collectionid, //收款方式
        status: this.status_tab == "-1" ? "" : this.status_tab, //状态
        permissions_id_string: str,
        page,
        branch_user_name,
        contract_number:this.contract_number,//合同编号
      };
      this.getOrderdataList(data);
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); // let strDate = Y + M + D;
      let strDate = Y + M + D;
      return strDate;
    },
    handleReset() {
      //查询重置
      ++this.isResouceShow;
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "order_list") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      this.contract_number = '';
      this.branch_user_name = "";
      this.policy_name = "";
      this.search_company_id = "";
      this.search_phone=""
      this.search_name = ""; //提交人
      this.search_product_id = ""; //产品
      this.search_corporate_name = ""; //公司名称
      this.search_start_time = []; //开始时间
      // this.search_end_time = ""; //结束时间
      (this.search_start_money = ""), //开始金额
        (this.search_end_money = ""), //结束金额
        (this.search_order = ""); //订单号
      // this.sear
      this.collection_id = ""; //收款方式
      this.status = ""; //状态
      this.options = [];
      setTimeout(() => {
        selectOrderCompanyWhere().then((res) => {
          if (res.data.code == 200) {
            function dg(list) {
              list.forEach((item) => {
                item.label = item.name;
                item.value = item.id;
                if (item.data) {
                  item.children = item.data;
                  dg(item.data);
                }
              });
              return list;
            }
            let list = res.data.data;
            dg(list);
            this.options = list;
          }
        });
      }, 500);

      this.getOrderdataList({
        permissions_id_string: str,
        status: this.status_tab == "-1" ? "" : this.status_tab,
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    handleDetail(row) {
      console.log(row,this.role_alldata.data)
      //查看详情
      let data = {};
      let str = "";
      let strr = "";
      let delstr = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "order_detail") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
        if (this.role_alldata.data[i].permissions == "order_list") {
          strr = this.role_alldata.data[i].permissions_id_string;
        }
        if (this.role_alldata.data[i].permissions == "del_Arrival") {
          delstr = this.role_alldata.data[i].permissions_id_string;
        }
      }
     
      if (row.command) {
        //更多
        if (row.command == -1) {
          //编辑
          this.order_to_time = "";
          this.achievement_time = "";
          this.command = row.command;
          data = {
            order_data_id: row.row.id,
            permissions_id_string: str,
          };
        }
        if (row.command == 2) {
          //删除
          this.$confirm("是否删除此条订单, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              del({
                order_data_id: row.row.id,
                permissions_id_string: delstr,
              }).then((res) => {
                if (res.data.code == 200) {
                  this.$message({
                    message: res.data.message,
                    type: "success",
                    center: true,
                  });
                  this.getOrderdataList({
                    permissions_id_string: strr,
                    page: this.currentPage,
                  });
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          return;
        }
      } else {
        this.command = row.status;
        data = {
          order_data_id: row.id,
          permissions_id_string: str,
        };
      }
      orderDetail(data).then((res) => {
     
        if (res.data.code == 200) {
          this.collectionList.forEach((item) => {
          
            if (item.id == res.data.data.data.collection_id) {
              res.data.data.data.collection_text = item.name;
            }
          });
          if (res.data.data.data.promotion) {
            this.promotion.forEach((item) => {
              if (item.value == res.data.data.data.promotion.type) {
                res.data.data.data.promotion.text = item.label;
              }
            });
          } else {
            res.data.data.data.promotion = {
              text: "----",
              money: "----",
            };
          }
          let historyList = res.data.data.data.history;
          let num = 0;
          if (historyList.length > 0) {
            historyList.forEach((itm, idx) => {
              if (itm.order_data_branch.length == 0) {
                //历史到账记录无分单记录
                // console.log('历史到账记录无分单记录',itm.order_data_branch);
                // let obj = {
                //     money:'暂无分单信息',
                //     user:{
                //         name:'暂无分单人'
                //     }
                // }
                // itm.order_data_branch.push(obj)
              } else {
                //历史到账有分单记录
                itm.order_data_branch.forEach((attr, i) => {
                  if (itm.user_id == attr.user_id) {
                    itm.order_data_branch.splice(i, 1);
                  }
                });
                if (itm.order_data_branch == 0) {
                  let obj = {
                    money: "----",
                    user: {
                      name: "----",
                    },
                  };
                  itm.order_data_branch.push(obj);
                }
              }
              num += Number(itm.to_money);
            });
          }
          let detail = res.data.data.data;
          detail.history_money_all = num.toFixed(2);
          if (detail.order.contract_file == "[]") {
            detail.order.contract_file = JSON.parse(detail.order.contract_file);
          }
          if (detail.collection_file == "[]") {
            detail.collection_file = JSON.parse(detail.collection_file);
          }

          let collection_file_url = [];
          let contract_file_url = [];
          if (detail.collection_file.length > 0) {
            detail.collection_file.forEach((item) => {
              collection_file_url.push(item.file_url);
            });
            detail.collection_file_url = collection_file_url;
          } else {
            detail.collection_file_url = collection_file_url;
          }
          detail.order.contract_file.forEach((item) => {
            contract_file_url.push(item.file_url);
          });
          detail.order.contract_file_url = contract_file_url;

          // company_edit
          this.company_edit = detail.order_data_branch_one.companys.id;
          // console.log(detail);
          this.order_detail = detail;
          this.isDetail = true;
          this.isMc = true;
        }
        if (res.data.code == 400) {
          this.$message({
            message: res.data.message,
            type: "error",
            center: true,
          });
        }
        // console.log(this.order_detail);
      });
    },
    handleEdit() {
      //编辑保存
      let str = "";
      let strr = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "change_Arrival") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
        if (this.role_alldata.data[i].permissions == "order_list") {
          strr = this.role_alldata.data[i].permissions_id_string;
        }
      }
      let { order_detail, order_to_time, collectionList, achievement_time } =
        this;
      let collection_id = ""; //收款方式
      collectionList.forEach((item) => {
        if (item.name == order_detail.collection_text) {
          collection_id = item.id;
        }
      });
      if (order_to_time == "") {
        order_to_time = order_detail.to_time;
      }
      if (achievement_time == "") {
        achievement_time = order_detail.order_data_branch_one.achievement_time;
      }
      let data = {
        order_data_id: order_detail.id,
        collection_id,
        to_time: order_to_time,
        permissions_id_string: str,
        achievement_time,
      };
      edit(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
            center: true,
          });
          (this.isMc = false), (this.isDetail = false);
          this.handleFind(this.currentPage);
          // this.getOrderdataList({
          //   page: this.currentPage,
          //   permissions_id_string: strr,
          // });
        }
      });
    },
    beforCommand(i, row) {
      //下拉菜单传参
      return {
        command: i,
        row: row,
      };
    },
    handleClose(i) {
      if (i == 1) {
        //关闭详情
        this.isDetail = false;
        this.isMc = false;
      }
      if (i == 2) {
        this.isDetail = true;
        this.isRejext = false;
        this.isMc = true;
      }
    },

    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.handleFind(this.currentPage);
    },
    handleReject(i) {
      if (i == 1) {
        this.isRejext_text = "财务备注";
      }
      if (i == 2) {
        //驳回
        this.isRejext_text = "驳回原因";
      }
      if (i == 3) {
        //撤销
        this.isRejext_text = "撤销原因";
      }
      this.textarea = "";
      this.status_type = i;
      this.isDetail = false;
      this.isMc = true;
      this.isRejext = true;
    },
    handleOk() {
      //订单审核驳回和撤销确定按钮  还有通过呢~
      let str = "";
      for (let i in this.role_alldata.data) {
        if (this.role_alldata.data[i].permissions == "order_editStatus") {
          str = this.role_alldata.data[i].permissions_id_string;
        }
      }
      editStatus({
        order_data_id: this.order_detail.id,
        status_type: this.status_type,
        reason: this.textarea,
        permissions_id_string: str,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
            center: true,
          });
          this.isRejext = false;
          this.isMc = false;
          this.isDetail = false;
          this.handleFind(this.currentPage);
          // this.getOrderdataList({
          //   page: this.currentPage,
          //   permissions_id_string: strr,
          // });
        }
        if (res.data.code == 400) {
          this.$message({
            message: res.data.message,
            type: "error",
            center: true,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.login_mc {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
}
.img_show {
  width: 300px;
  max-height: 600px;
  margin: auto;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  img {
    width: 300px;
    max-height: 600px;
  }
}
.dzgl {
  flex: 1;
  height: 100%;
  margin: 20px;
  .el-main {
    background: #fff;
    padding: 0;
    width: 100%;
    .finace_main_header {
      max-height: 180px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      padding-top: 10px;
      padding-bottom: 20px;
      .main_header {
        width: 20%;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        .header_buttom {
          display: flex;
          margin-top: 10px;
          height: 32px;
          align-items: center;
          width: 100%;
          justify-content: flex-end;
          div {
            width: 70px;
            height: 32px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            border-radius: 2px;
            cursor: pointer;
          }
          .finance_find {
            color: #ffffff;
            opacity: 1;
            background: #3a85fc;
          }
          .finance_reset {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
            color: rgba(0, 0, 0, 0.6);
            margin-left: 20px;
          }
          .finance_up {
            font-size: 14px;
            line-height: 19px;
            color: #3a85fc;
          }
        }
      }

      .main_header_line:nth-child(2) {
        margin: 20px 0;
      }
      .main_header_line {
        width: 100%;
        display: flex;
        height: 32px;
        align-items: center;
        .saa {
          width: 0;
        }
        .date_time {
          .el-input__inner {
            padding-left: 15px;
          }
        }
        .el-cascader {
          flex: 1;
          height: 32px;
        }
        .line_name {
          width: 70px;
          text-align: right;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #595959;
          opacity: 1;
          white-space: nowrap;
        }
        .inline-input {
          height: 32px;
        }
        .line_input {
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: space-between;
          .el-date-editor--daterange {
            width: 100%;
            height: 32px;
            line-height: 32px;
            .el-range__icon {
              line-height: 100%;
            }
            .el-range-separator {
              line-height: 24px;
            }
            .el-range__close-icon {
              line-height: 120%;
            }
          }
          .el-input {
            width: 40%;
          }
          i {
            color: #ccc;
          }
          .el-date-editor {
            padding: 3px 5px;
            span {
              margin-right: 13px;
            }
            .el-input {
              width: 50%;
            }
            .el-range__close-icon {
              width: 5%;
              display: flex;
              height: 100%;
              align-items: center;
            }
          }
        }
        .el-select {
          height: 32px;
          display: flex;
          align-items: center;
          flex: 1;
        }

        .el-input {
          display: flex;
          align-items: center;
          height: 100%;
          .el-input__suffix {
            display: flex;
            align-items: center;
            line-height: 32px;
          }
          .el-input__icon {
            line-height: 0;
          }
          .el-input__inner {
            height: 100%;
            padding-right: 0;
          }
        }
      }
    }
    .tkgl_header_search_tab {
      display: flex;
      border-bottom: 1px solid #e1e1e1;
      margin: 0 30px;
      div {
        cursor: pointer;
      }
      .tkgl_header_search_tab_one {
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
        }
        div:last-child {
          width: 46px;
          height: 2px;
          opacity: 1;
        }
        .tab_tit_active {
          color: #3a85fc;
        }
        .tab_active {
          background: #3a85fc;
        }
      }
    }
  }
}
.content {
  padding: 0 30px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  .wait_status {
    //0  待审核
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #faad14;
    opacity: 1;
    border-radius: 50%;
    margin-right: 5px;
  }
  .pass_status {
    //1 通过
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #1dd0bd;
    border: 1px solid #1dd0bd;
    border-radius: 50%;
    opacity: 1;
    margin-right: 5px;
  }
  .reject_status {
    //2驳回
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #ff5c5c;
    border-radius: 50%;
    opacity: 1;
    margin-right: 5px;
  }
  .revoke_status {
    //  3 撤销
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #3299eb;
    border-radius: 50%;
    opacity: 1;
    margin-right: 5px;
  }
  .cell {
    display: flex;
    align-items: center;
  }
  // height: 100%;
  .cz {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3a85fc;
    opacity: 1;
    div {
      line-height: 14px;
      cursor: pointer;
    }
    .cz_detail {
      border-right: 1px solid #ccc;
      padding-right: 10px;
      white-space: nowrap;
    }
    .el-dropdown {
      padding-left: 10px;
      color: #3a85fc;
    }
  }
  .export {
    width: 100%;
    height: 60px;
    background: rgba(0, 0, 0, 0.04);
    opacity: 1;
    display: flex;
    align-items: center;
    .export_button {
      width: 80px;
      height: 32px;
      background: #3a85fc;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      margin-left: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      color: #ffffff;
      opacity: 1;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  // table,tbody,thead {
  // width: 100% !important;
  // }
  .el-table__header tr,
  .el-table__header th {
    padding: 0;
    height: 40px;
  }
  .el-table__body tr,
  .el-table__body td {
    padding: 0;
    height: 60px;
  }
  .el-table__header-wrapper {
    background: rgba(0, 0, 0, 0.04);
  }
  // .el-table{
  // width: 100% !important;
  // .el-table__header-wrapper{
  // table{
  // table-layout:auto;
  // width: 100% !important;
  // }
  // }
  // .el-table__body-wrapper{
  // table{
  // table-layout:auto;
  // width: 100% !important;
  // }
  // }
  // }
  .el-table thead {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    height: 50px;

    .el-table td,
    .has-gutter {
      background: rgba(0, 0, 0, 0.04);
    }
  }
  .el-table__body tr {
    height: 50px;
  }
  .el-table__body td {
    height: 50px;
  }
  .el-table th.gutter {
    display: table-cell !important;
  }
  .el-table colgroup.gutter {
    display: table-cell !important;
  }
  // colgroup {
  // position: absolute;
  // width: 100% !important;
  // col{
  //     flex: 1;
  //     text-align: center;
  // }
  // }
  // col {
  //     flex: 1;
  //     text-align: center;
  // }
  .block {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
.detail {
  width: 59%;
  height: 90%;
  background: #ffffff;
  opacity: 1;
  border-radius: 10px;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  min-width: 858px;
  .detail_tit {
    height: 55px;
    width: 100%;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.8);
    opacity: 1;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    border-bottom: 1px solid #f5f5f5;
    div {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      i {
        padding: 15px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }

  .detail_content {
    flex: 1;
    // display: flex;
    // justify-content: space-between;
    overflow-y: auto;
    padding: 20px 20px;
    .detail_content_status {
      margin: 10px 0;
    }
    .detail_content_box {
      margin-top: 20px;
      .detail_content_box_flex {
        width: 100%;
        display: flex;
        .detail_content_box_left {
          width: 50%;
          // margin-left: 30px;
          .box_left_head {
            width: 100%;
            height: 40px;
            background: #fafafa;
            opacity: 1;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.8);
            opacity: 1;
            padding-left: 30px;
            margin-bottom: 10px;
            .box_left_head_L {
              width: 2px;
              height: 12px;
              background: #3a85fc;
              opacity: 1;
              margin-right: 10px;
            }
          }
          .no_body {
            color: #ccc;
            margin: 20px auto;
            margin-left: 50px;
          }
          .detail_content_box_line {
            display: flex;
            padding-left: 25px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            .detail_content_box_line_tit {
              color: rgba(0, 0, 0, 0.5);
              width: 125px;
              text-align: left;
            }

            .detail_content_box_line_info {
              color: rgba(0, 0, 0, 0.8);
              .el-image-viewer__close {
                color: #fff;
              }
            }
            .el-select {
              width: 300px;
              .el-input {
                width: 100%;
                .el-input--suffix .el-input__inner {
                  width: 300px;
                }
              }
            }
          }
          .detail_content_box_line_img {
            height: 60px;
            display: flex;
            padding-left: 25px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            margin: 10px 0;
            img[src=""],
            img:not([src]) {
              opacity: 0;
            }
            .detail_content_box_line_tit {
              width: 125px;
              text-align: left;
            }
            // .detail_content_box_line_info {
            //   img {
            //     width: 60px;
            //     height: 60px;
            //     margin-right: 10px;
            //     border-radius: 5px;
            //   }
            // }
          }
        }
      }
      .detail_content_box_noflex {
        display: flex;
        padding-left: 25px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        div:first-child {
          width: 90px;
          text-align: left;
        }
        .detail_content_box_remarks_tit {
          color: rgba(0, 0, 0, 0.5);
        }
        .detail_content_box_remarks_info {
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
  .detail_old {
    border-top: 1px dotted rgba(0, 0, 0, 0.2);
    padding: 0 34px;
    height: 26%;
    overflow: auto;
    .detail_old_tit {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.6);
      opacity: 1;
      display: flex;
      height: 50px;
      align-items: center;
      justify-content: space-between;
      .circular {
        width: 6px;
        height: 6px;
        background: #569fff;
        border-radius: 50%;
        opacity: 1;
        margin-right: 5px;
      }
    }
    .old_line_box {
      cursor: pointer;
      padding: 0 10px;
      width: 100%;
      .orde_history {
        font-size: 14px;
        color: #ccc;
        width: 100%;
        text-align: center;
      }
      .old_line_header {
        display: flex;
        height: 40px;
        background: #f5f5f5;
        width: 100%;
        border-bottom: 1px solid #ccc;
        div {
          box-sizing: border-box;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #ccc;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
        }
        div:nth-of-type(1) {
          border-left: 1px solid #f5f5f5;
          flex: 1;
        }
        div:nth-of-type(2) {
          flex: 1;
        }
        div:nth-of-type(3) {
          flex: 1;
        }
        div:nth-of-type(4) {
          flex: 2;
        }
        div:nth-of-type(5) {
          flex: 1.5;
        }
        div:nth-of-type(6) {
          flex: 1.5;
        }
        div:nth-of-type(7) {
          flex: 1;
        }
        div:nth-of-type(8) {
          flex: 1;
        }
        div:nth-of-type(9) {
          flex: 1;
          border-right: 1px solid #f5f5f5;
        }
      }
      .old_line_info {
        display: flex;
        min-height: 40px;
        background: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        width: 100%;
        border-bottom: 1px solid #ccc;
        div {
          box-sizing: border-box;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #ccc;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
          overflow: hidden;
        }
        .imgbox {
          display: flex;
          align-items: center;
          justify-content: space-around;
          img {
            width: 40%;
          }
        }
        div:nth-of-type(1) {
          border-left: 1px solid #ccc;
          flex: 1;
        }
        div:nth-of-type(2) {
          flex: 1;
        }
        div:nth-of-type(3) {
          flex: 1;
        }
        div:nth-of-type(4) {
          flex: 2;
        }
        div:nth-of-type(5) {
          flex: 1.5;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          div {
            flex: 1;
          }
        }
        div:nth-of-type(6) {
          flex: 1.5;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          div {
            flex: 1;
          }
        }
        div:nth-of-type(7) {
          flex: 1;
        }
        div:nth-of-type(8) {
          flex: 1;
        }
        div:nth-of-type(9) {
          flex: 1;
          border-right: 1px solid #ccc;
        }
        .fendan {
          border: none !important;
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
  .detail_buttom {
    height: 40px;
    display: flex;
    justify-content: flex-end;
    // position: absolute;
    // right: 100px;
    // bottom: 26px;
    margin-right: 100px;
    margin-top: 22px;
    margin-bottom: 26px;
    div {
      cursor: pointer;
      width: 80px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 20px;
    }
    .reject {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
      opacity: 1;
      color: rgba(0, 0, 0, 0.5);
    }
    .pass {
      background: #3a85fc;
      color: #ffffff;
    }
    .revoke {
      background: #3a85fc;
      color: #ffffff;
    }
  }
}
.reject_box {
  width: 645px;
  height: 426px;
  background: #ffffff;
  opacity: 1;
  border-radius: 10px;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  .reject_box_header {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    div {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.8);
      opacity: 1;
      width: 50%;
      display: flex;
      justify-content: space-between;
      i {
        margin-right: 30px;
      }
    }
  }
  .reject_box_tlt {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.8);
    opacity: 1;
    margin: 25px auto;
    margin-bottom: 0;
    width: 547px;
  }
  .reject_box_textarea {
    width: 547px;
    height: 200px;
    background: #ffffff;
    opacity: 1;
    border-radius: 4px;
    margin: 15px auto;
    .el-textarea {
      height: 100%;
      width: 100%;
      .el-textarea__inner {
        height: 100%;
        width: 100%;
        font-size: 20px;
      }
    }
  }
  .reject_box_buttom {
    width: 547px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 40px auto;
    div {
      width: 80px;
      height: 40px;
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    div:first-child {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
      color: rgba(0, 0, 0, 0.5);
    }
    div:last-child {
      margin-left: 20px;
      background: #3a85fc;
      color: #ffffff;
    }
  }
}
.el-picker-panel__footer {
  //到账详情 到账时间 选择器 底部的此刻按钮
  .el-button--text {
    display: none;
  }
}
.el-dropdown-menu__item {
  white-space: nowrap; //编辑删除hover 效果
}
// .el-picker-panel__content{          //到账详情 到账时间 选择器 周显示
//     table:first-child{
//         tbody{
//             tr:first-child{
//                 display: none;
//             }x   `
//         }
//     }
// }
</style>