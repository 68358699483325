<template>
  <div class="customer">
    <el-main>
      <div class="customer_header">
        <div class="customer_header_screen" style="margin-top: 0px; flex-wrap: wrap; height: 76px">
          <div class="customer_header_screen_box" style="margin-left: 0">
            <div class="customer_header_screen_box_tit">公司名称：</div>
            <div class="screen_input">
              <input type="text" v-model="customer_name" placeholder="请输入公司名称" style="padding-left: 15px" />
            </div>
          </div>
          <div class="customer_header_screen_box">
            <div class="customer_header_screen_box_tit">公司联系人：</div>
            <div class="screen_input">
              <input type="text" v-model="select_contacts" placeholder="请输入公司联系人" style="padding-left: 15px" />
            </div>
          </div>
          <div class="customer_header_screen_box">
            <div class="customer_header_screen_box_tit">公司电话：</div>
            <div class="screen_input">
              <input type="number" v-model="select_phone" placeholder="请输入公司电话" style="padding-left: 15px" />
            </div>
          </div>
          <div class="customer_header_screen_box">
            <div class="customer_header_screen_box_tit">经营产品：</div>
            <div class="screen_input">
              <input type="text" v-model="select_management_product" placeholder="请输入经营产品" style="padding-left: 15px" />
            </div>
          </div>
          <div class="customer_header_screen_box" style="margin-left: 0">
            <div class="customer_header_screen_box_tit">省市区：</div>
            <div class="screen_input" style="border: 0">
              <el-cascader
                v-model="city_name_id"
                :options="cityList"
                :props="props"
                separator=" - "
                placeholder="请选择省市区"
                ref="cascader"
                @change="handleChange($event)"
              >
              </el-cascader>
            </div>
          </div>
          <div class="customer_header_screen_box">
            <el-tooltip class="item" effect="dark" content="进入公海时间（不累计，领回后重置）" placement="top-start">
              <div class="customer_header_screen_box_tit">
                <i class="iconfont icon-wenhao"></i>
                漂龄：
              </div>
            </el-tooltip>

            <div class="screen_input" style="border: 0">
              <el-select v-model="floating_age_select" clearable placeholder="请选择意向">
                <el-option v-for="item in floating_age_select_list" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
          </div>
          <div class="customer_header_screen_box">
            <el-tooltip class="item" effect="dark" content="一共进入公海次数（会累计）" placement="top-start">
              <div class="customer_header_screen_box_tit"><i class="iconfont icon-wenhao"></i>漂次：</div>
            </el-tooltip>
            <div class="screen_input" style="border: 0">
              <el-select v-model="floating_num_select" clearable placeholder="请选择意向">
                <el-option v-for="item in floating_num_select_list" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
          </div>
          <div class="customer_header_screen_box customer_header_bottom">
            <div @click="handleScreen">查询</div>
            <div @click="handleReset">重置</div>
          </div>
        </div>

        <div class="customer_all">
          <div class="customer_add pointer" @click="handleAdd"><i class="el-icon-plus"></i>添加客户</div>
          <div class="export" style="width: 87%">
            <div class="export_button" @click="onGet">
              <i class="el-icon-user" style="margin-right: 5px"></i>
              领取
            </div>
            <div class="export_button" @click="exportDialogVisible = true">
              <i class="el-icon-files" style="margin-right: 5px"></i>
              导出
            </div>
          </div>
          <!--客户总人数：{{ total }}人-->
        </div>
      </div>
      <div style="width: 99.9%">
        <el-table
          :data="tableData"
          border
          v-loading="listLoading"
          :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="公司名称" :key="1" width="170">
            <template scope="scope">
              <div>
                <img
                  :src="
                    scope.row.wechat == '' || scope.row.wechat == null || scope.row.wechat == '[]'
                      ? require('../../assets/img/wx.png')
                      : require('../../assets/img/wx_ing.png')
                  "
                  class="wx"
                />
                <div>{{ scope.row.customer_res ? scope.row.customer_res.name : "--" }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="客户信息" :key="2" width="350">
            <template scope="scope">
              <div>
                <div>
                  {{ scope.row.customer_res ? scope.row.customer_res.contacts : "--" }} 【{{
                    scope.row.customer_contacts ? scope.row.customer_contacts.contacts_position.name : "--"
                  }}】 -{{ scope.row.customer_contacts ? scope.row.customer_contacts.phone : "--" }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="意向产品" :key="3">
            <template scope="scope">
              <div>
                <div v-for="(item, index) in scope.row.my_customer_product" :key="index">
                  {{ item.product_name.product_name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="漂次" :key="4" scope="scope">
            <template scope="scope">
              <div>{{ scope.row.floating_num && scope.row.floating_num != null ? scope.row.floating_num : "--" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="漂龄" :key="6">
            <template scope="scope">
              <div>{{ scope.row.floating_age && scope.row.floating_age != null ? scope.row.floating_age : "--" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template scope="scope">
              <i></i>
              <div class="customer_cz pointer" style="display: flex; color: #3a85fc">
                <div @click="handleClickcz(scope.row, 1)">领取</div>

                <div @click="handleClickcz(scope.row, 2)">查看</div>
                <div @click="delRow(scope.row)" v-if="delRowFlag">删除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="15"
          el-pagination
          layout="total,prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-main>
    <div class="login_mc" v-if="isMc"></div>
    <add_customer_gh v-if="isaddcustomer" @Close="handleClose"></add_customer_gh>

    <!-- 导出弹窗 -->
    <el-dialog title="请选择导出时间段" :visible.sync="exportDialogVisible" width="30%" @close="exportTime = []">
      <div style="height: 20vh; display: flex; justify-content: center; align-items: center; gap: 1em">
        <el-date-picker
          v-model="exportTime"
          type="monthrange"
          range-separator="至"
          value-format="yyyy-MM"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          :clearable="false"
        >
        </el-date-picker>
        <el-button @click="exportTime = []">清除</el-button>
      </div>
      <template #footer>
        <el-button type="" @click="exportDialogVisible = false">取消</el-button>
        <el-button type="primary" :disabled="loading" @click="onExport">{{ loading ? "正在导出" : "确定" }} </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
let xlsx = require("json-as-xlsx");
// import myWorker from './xlsx.worker.js' //上一任傻b开发引了js但是没往仓库里放，只能这样了。
import Add_customer_gh from "@/components/Marketing/Add_customer_gh";
import { userCustomerCapacity, publicCustomer, receivePublicCustomer, delWaterTableRow, publicCustomerExport } from "@/api/User/data";
import { getCity } from "@/api/User/customer";
export default {
  components: {
    Add_customer_gh: Add_customer_gh,
  },
  data() {
    return {
      loading: false,
      exportDialogVisible: false,
      exportTime: [],
      city_name_id: [],
      cityList: [], // 地址
      centerDialogVisible: false,
      is_water: true,
      multipleSelection: [], //全选
      customer_name: "", //客户名称
      floating_age_select_list: [
        { value: "1", label: "10天以下" },
        { value: "2", label: "20~50天" },
        { value: "3", label: "50~100天" },
        { value: "4", label: "100天以上" },
      ], //意向
      isMc: false, //蒙层
      floating_age_select: "", //漂领
      isaddcustomer: false, //是否新增客户
      tableData: [],
      currentPage: 1, //页码
      total: 0, //列表总数量
      floating_num_select_list: [
        { value: "1", label: "1~3次" },
        { value: "2", label: "4~10次" },
        { value: "3", label: "10次以上" },
      ], //票次
      floating_num_select: "",
      have: "", //已有客户数量
      capacity: "", //最大客户数量
      per_page: "", //每页条数
      page: 1,
      prooptions: [], //产品列表
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          console.log(node);
          if (node.data) {
            let node_data = node.data;
            getCity({
              type: node.level + 1,
              parent_id: node_data.id,
            }).then((res) => {
              let list = res.data.data;
              list.forEach((item) => {
                item.label = item.name;
                item.value = item.id;
                item.leaf = node.level >= 2;
              });
              resolve(list);
            });
          }
        },
        checkStrictly: true,
      },
      select_contacts: "", // 联系人
      select_phone: "", //    电话
      select_management_product: "", // 经营产品
      select_province_id: "", // 省id
      select_city_id: "", //市id
      select_area_id: "", //区
      roleList: "",
      delRowFlag: false,
      listLoading: false,
    };
  },
  created() {
    this.roleList = JSON.parse(localStorage.getItem("user")).role_permissions; //权限列表
    //    扩容
    userCustomerCapacity({
      type: 1,
    }).then((res) => {
      this.have = res.data.data.have;
      this.capacity = res.data.data.capacity;
    });
    getCity({ type: 1 }).then((res) => {
      let list = res.data.data;
      list.forEach((item) => {
        item.label = item.name;
        item.value = item.id;
      });
      this.cityList = list;
    });
    this.roleList.forEach((item) => {
      if (item.data) {
        item.data.forEach((el) => {
          if (el.permissions == "publicCustomerDel") {
            this.delRowFlag = true;
          }
        });
      }
    });
    this.getTable();
  },
  mounted() {
    let worder = new myWorker();
    console.log("worker", worker);

    worker.postMessage({ method: "echo", args: ["Work"] });
    worker.onmessage = (event) => {
      console.log(event.data);
    };
  },
  //全选
  methods: {
    onExportClick() {},
    onExport() {
      if (this.exportTime?.length !== 2) {
        this.$message.error("请选择时间");
        return;
      }
      this.loading = true;
      let data = {
        // page: this.page,
        name: this.customer_name,
        start_time: this.exportTime[0],
        end_time: this.exportTime[1],
        floating_num_select: this.floating_num_select,
        floating_age_select: this.floating_age_select,
        select_contacts: this.select_contacts,
        select_phone: this.select_phone,
        select_management_product: this.select_management_product,
        select_province_id: this.select_province_id,
        select_city_id: this.select_city_id,
        select_area_id: this.select_area_id,
      };
      publicCustomerExport(data)
        .then((res) => {
          console.log("publicCustomerExport", res);
          let down = document.createElement("a");
          down.href = res.data.data;
          down.dispatchEvent(new MouseEvent("click"));
          this.loading = false;
          this.exportDialogVisible = false;
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error("数据过多 导出失败");
          this.loading = false;
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClickcz(row, i) {
      //操作
      //1 领取  2详情
      if (i == 1) {
        this.$confirm("确认要领取该客户吗？", "确认领取", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            receivePublicCustomer({
              customer_id: row.id,
            }).then((res) => {
              if ((res.data.code = 200)) {
                this.$message({
                  type: "success",
                  message: "领取成功!",
                });
                this.getTable();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
      if (i == 2) {
        this.$router.push({
          name: "customerdetail",
          params: {
            my_customer_id: row.id,
            type: 1,
            is_gh: 1,
            customer_id: row.customer_id,
            customer_type: 2,
          },
        });
      }
    },
    //删除
    delRow(row) {
      this.$confirm("此操作将永久删, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delWaterTableRow({ my_customer_id: row.id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getTable();
            }
          });
        })
        .catch(() => {});
    },
    //批量领取
    onGet() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: "error",
          message: "还没有选择客户",
        });
      } else {
        var customer_ids = this.multipleSelection.map((item) => {
          return item.id;
        });
        this.$confirm("确认要领取该客户吗？", "确认领取", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            receivePublicCustomer({
              customer_id: customer_ids.join(","),
            }).then((res) => {
              if ((res.data.code = 200)) {
                this.$message({
                  type: "success",
                  message: "领取成功!",
                });
                this.getTable();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    //添加客户
    handleAdd() {
      this.isaddcustomer = true;
      this.isMc = true;
    },
    //查询
    handleScreen() {
      if (
        this.customer_name == "" &&
        this.floating_num_select == "" &&
        this.floating_age_select == "" &&
        this.select_contacts == "" &&
        this.select_phone == "" &&
        this.select_management_product == "" &&
        this.select_province_id == ""
      ) {
        this.$message({
          type: "error",
          message: "请输入搜索信息",
        });
        return;
      }
      this.getTable();
    },
    handleReset() {
      //重置
      this.customer_name = "";
      this.currentPage = 1;
      this.floating_age_select = "";
      this.floating_num_select = "";
      (this.select_contacts = ""), // 联系人
        (this.select_phone = ""), //    电话
        (this.select_management_product = ""), // 经营产品
        (this.select_province_id = ""), // 省id
        (this.select_city_id = ""), //市id
        (this.page = 1);
      // 清空选中的节点
      this.$refs.cascader.$refs.panel.clearCheckedNodes();
      // 设置为空可以让节点不高亮显示
      this.$refs.cascader.$refs.panel.activePath = [];

      this.getTable();
    },
    //    领取
    exporttable() {},
    //切换页面
    handleCurrentChange(val) {
      this.page = val;
      this.getTable();
    },
    //关闭弹窗
    handleClose() {
      this.isaddcustomer = false;
      this.isMc = false;
      this.getTable();
    },
    //获取列表
    getTable() {
      this.listLoading = true;
      publicCustomer({
        page: this.page,

        name: this.customer_name,
        floating_num_select: this.floating_num_select,
        floating_age_select: this.floating_age_select,
        select_contacts: this.select_contacts,
        select_phone: this.select_phone,
        select_management_product: this.select_management_product,
        select_province_id: this.select_province_id,
        select_city_id: this.select_city_id,
        select_area_id: this.select_area_id,
      })
        .then((res) => {
          (this.tableData = res.data.data.data),
            (this.current_page = res.data.data.current_page),
            (this.per_page = res.data.data.per_page),
            (this.total = res.data.data.total);
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleChange(e) {
      this.select_province_id = e[0];
      this.select_city_id = e[1];
      this.select_area_id = e[2];
    },
  },
};
</script>

<style lang="scss" scoped>
.el-cascader {
  width: 100%;
}

.el-radio-group,
.el-checkbox-group {
  width: 70%;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.flow_kind .form_input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 80%;
}

.flow_kind .form_input::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
}

.el-table_1_column_2 {
  position: relative;
}

.wx {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 32px;
  height: 32px;
}

.customer_header_screen_box {
  margin-bottom: 20px;

  i {
    color: #3a85fc;
  }
}

.screen_input {
  width: 320px;
}

.el-input {
  width: 90% !important;
}
</style>
