import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Layout from "../../src/Layout";

//盛世云平台
import CloudList from "../views/cloud_platform/CloudList"; //展示列表
import CloudDetails from "../views/cloud_platform/CloudDetails"; //展示详情
// import CloudPlatform from  '../views/cloud_platform/CloudPlatform' //配置
import CloudAdministration from "../components/system/CloudAdministration"; //产品管理
import CloudSet from "../views/cloud_platform/CloudSet"; //分类配置
import {
  Finance_center,
  Role,
  FinaceDzgl,
  Marketing,
  customer,
  customer_detail,
  Account,
  human,
  setting_center,
  human_child,
  achievement,
  order_center,
  department_follow,
  Achievement_center,
  Promotion,
  Arrival,
  order_list,
  order_detail,
  Approval, //审批中心
  Promotion_audit,
  Refund_audit,
  Mission_audit,
  drawback,
  play_bill,
  play_bill_copy,
  Refund,
  Node_mission,
  mission_control,
  Deal_customer,
  Pk_list,
  pk_management,
  Battle,
  Month_data,
  Historical_data,
  Historical_data2,
  Analysis_data,
  all_can_see_index,
  Quit,
  Customer_assignment,
  distribute,
  prodect_data,
  service,
  explain,
  edit_explain,
  integral,
  Campaign_configuration,
  visit_analysis,
  international_waters,
  Handover_customer,
  DailyArrival,
  Dailycustomer,
  customerSerivces,
} from "../components/index.js";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/newDataIndex",
    component: () => import("@/components/all_can_see/newDataIndex"),
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/all_can_see",
        component: all_can_see_index,
      },
      {
        path: "index",
        redirect: "/Finance",
      },
      {
        path: "Finance",
        component: Finance_center,
        // redirect:'/Finance/FinaceDzgl',
        children: [
          {
            path: "/Finance/FinaceDzgl",
            component: FinaceDzgl,
          },
          {
            path: "/Finance/Refund",
            component: Refund,
          },
          {
            path: "/Finance/Promotion_audit",
            component: Promotion_audit,
          },
          {
            path: "/Finance/Refund_audit",
            component: Refund_audit,
          },
          {
            path: "/Finance/Node_mission",
            component: Node_mission,
          },
          {
            path: "/Finance/Mission_audit",
            component: Mission_audit,
          },
          {
            path: "/Finance/mission_control",
            component: mission_control,
          },
          {
            path: "/Finance/distribute",
            component: distribute,
          },
        ],
      },
      {
        path: "resources",
        component: human,
        children: [
          {
            path: "/human_child",
            component: human_child,
          },
        ],
      },
      {
        path: "system", //路由名
        component: setting_center, //路由指向组件
        children: [
          {
            path: "/system/explain",
            component: explain,
          },
          {
            path: "/system/edit_explain",
            component: edit_explain,
          },
          {
            path: "/human_child",
            component: human_child,
          },
        ],
      },
      {
        path: "Marketing",
        component: Marketing,
        redirect: "/Marketing/Account",
        children: [
          {
            path: "/Marketing/customer",
            component: customer,
            meta: {
              keepAlive: true,
              isBack: false,
            },
          },
          {
            path: "/Marketing/customer/detail",
            component: customer_detail,
            name: "customerdetail",
          },

          {
            path: "/Marketing/departmentfollow",
            component: department_follow,
            name: "departmentfollow",
          },
          {
            path: "/Marketing/Account",
            component: Account,
          },
          {
            path: "/Marketing/customer/achievement",
            component: achievement,
            name: "achievement",
          },
          {
            path: "/Marketing/customer/order",
            component: order_center,
            name: "ordercenter",
          },
          {
            path: "/Marketing/achievement_center",
            component: Achievement_center,
          },
          {
            path: "/Marketing/Promotion",
            component: Promotion,
          },
          {
            path: "/Marketing/order_list/order_detail",
            component: order_detail,
            name: "orderdetail",
          },
          {
            path: "/Marketing/order_list",
            component: order_list,
            name: "order_list",
            meta: {
              keepAlive: true,
              isBack: false,
            },
          },
          {
            path: "/Marketing/drawback",
            component: drawback,
            name: "drawback",
          },
          {
            path: "/Marketing/play_bill",
            component: play_bill,
            name: "play_bill",
          },
          {
            path: "/Marketing/play_bill_copy",
            component: play_bill_copy,
            name: "play_bill_copy",
          },
          {
            path: "/Marketing/Deal_customer",
            component: Deal_customer,
            name: "Deal_customer",
            meta: {
              keepAlive: true,
              isBack: false,
            },
          },
          {
            path: "/Marketing/service",
            component: service,
            name: "service",
          },
          {
            path: "/Marketing/management",
            component: pk_management,
            name: "management",
          },
          {
            path: "/Marketing/management/integral",
            component: integral,
            name: "integral",
          },
          {
            path: "/Marketing/configuration",
            component: Campaign_configuration,
            name: "configuration",
          },
          {
            path: "/Marketing/Pk_list",
            component: Pk_list,
            name: "Pk_list",
          },
          {
            path: "/Marketing/Battle",
            component: Battle,
            name: "Battle",
          },
          {
            path: "/Marketing/Month_data",
            component: Month_data,
            name: "Month_data",
          },
          {
            path: "/Marketing/Historical_data",
            component: Historical_data,
            name: "Historical_data",
          },
          {
            path: "/Marketing/Historical_data2",
            component: Historical_data2,
            name: "Historical_data2",
          },
          {
            path: "/Marketing/Analysis_data",
            component: Analysis_data,
            name: "Analysis_data",
          },
          {
            path: "/Marketing/prodect_data",
            component: prodect_data,
            name: "prodect_data",
          },
          {
            path: "/Marketing/Quit",
            component: Quit,
            name: "Quit",
          },
          {
            path: "/Marketing/Customer_assignment",
            component: Customer_assignment,
            name: "Customer_assignment",
          },
          {
            path: "/Marketing/visit_analysis",
            component: visit_analysis,
            name: "visit_analysis",
          },
          {
            path: "/Marketing/international_waters",
            component: international_waters,
            name: "international_waters",
            meta: {
              keepAlive: true,
              isBack: false,
            },
          },
          {
            path: "/Marketing/Handover_customer",
            component: Handover_customer,
            name: "Handover_customer",
            meta: {
              keepAlive: true,
              isBack: false,
            },
          },

          {
            path: "/Marketing/DailyArrival",
            component: DailyArrival,
            name: "DailyArrival",
          },
          {
            path: "/Marketing/Dailycustomer",
            component: Dailycustomer,
            name: "Dailycustomer",
          },
          {
            path: "/Marketing/customerSerivces",
            component: customerSerivces,
            name: "customerSerivces",
          },
        ],
      },
      {
        path: "Approval",
        component: Approval,
        redirect: "/Approval/Mission_audit",
        children: [
          {
            path: "/Approval/Promotion_audit",
            component: Promotion_audit,
          },
          // {
          //   path: "/Approval/Refund_audit",
          //   component: Refund_audit,
          // },
          {
            path: "/Approval/Mission_audit",
            component: Mission_audit,
          },
        ],
      },
      {
        path: "/cloud_platform",
        component: { render: (e) => e("router-view") },
        redirect: "/cloud_platform/CloudList",
        children: [
          {
            path: "CloudList",
            component: CloudList,
          },
          {
            path: "CloudSet",
            component: CloudSet,
          },
          {
            path: "CloudDetails",
            name: "CloudDetails",
            component: CloudDetails,
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

let newpath = "";
if (localStorage.getItem("token")) {
  if (localStorage.getItem("token").role_permissions) {
    newpath = JSON.parse(localStorage.getItem("token").role_permissions[0].permissions);
  }
}

router.beforeEach((to, from, next) => {
  var token = localStorage.getItem("token");
  var user = localStorage.getItem("user");
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (token || user || to.fullPath == "/") {
    //如果token 或者 去往路径为/ 代码执行
    next();
  } else {
    //没有token
    next();
    router.push({
      path: `/${newpath}`,
    });
  }
});

export default router;
