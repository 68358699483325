<template>
  <div class="cutomer_detail">
    <el-main>
      <div class="cutomer_detail_box" v-if="customerdetail">
        <div class="cutomer_detail_head">
          <div class="cutomer_detail_head_name" style="display: flex; align-items: center">
            <h1>客户信息</h1>
            <!--{{ customerdetail.customer_res.name }}-->
            <div class="cutomer_detail_button" v-if="type == 1 && is_gh != 1">
              <!--<div-->
              <!--@mouseover="handleMouseover(1)"-->
              <!--:class="Mouseover_id == 1 ? 'img_check' : ''"-->
              <!--@mouseleave="handleLeave"-->
              <!--@click="handleAdd_some(6)"-->
              <!--&gt;-->
              <!--<img-->
              <!--v-if="Mouseover_id == 1"-->
              <!--src="../../assets/img/cutomer_detail_plan.png"-->
              <!--alt=""-->
              <!--/>-->
              <!--<img-->
              <!--v-else-->
              <!--src="../../assets/img/cutomer_detail_plan_check.png"-->
              <!--alt=""-->
              <!--/>-->
              <!--新增计划-->
              <!--</div>-->
              <div
                @mouseover="handleMouseover(2)"
                :class="Mouseover_id == 2 ? 'img_check' : ''"
                @mouseleave="handleLeave"
                @click="handleAdd_some(5)"
              >
                <img v-if="Mouseover_id == 2" src="../../assets/img/cutomer_detail_Arrival_check.png" alt="" />
                <img v-else src="../../assets/img/cutomer_detail_Arrival.png" alt="" />
                新增到账
              </div>
              <div
                @mouseover="handleMouseover(3)"
                :class="Mouseover_id == 3 ? 'img_check' : ''"
                @mouseleave="handleLeave"
                @click="handleAdd_some(4)"
              >
                <img v-if="Mouseover_id == 3" src="../../assets/img/cutomer_detail_cx_check.png" alt="" />
                <img v-else src="../../assets/img/cutomer_detail_cx.png" alt="" />
                促销申请
              </div>
              <div
                @click="handleAdd_some(3)"
                @mouseover="handleMouseover(4)"
                :class="Mouseover_id == 4 ? 'img_check' : ''"
                @mouseleave="handleLeave"
                v-if="check_self == 1 && !my_customer_service_id"
              >
                <img v-if="Mouseover_id == 4" src="../../assets/img/cutomer_detail_edit.png" alt="" />
                <img v-else src="../../assets/img/cutomer_detail_edit_check.png" alt="" />
                编辑
              </div>
              <div
                @click="handleAdd_some(2)"
                @mouseover="handleMouseover(5)"
                :class="Mouseover_id == 5 ? 'img_check' : ''"
                @mouseleave="handleLeave"
                v-if="check_self == 1 && !my_customer_service_id"
              >
                <img v-if="Mouseover_id == 5" src="../../assets/img/cutomer_detail_pro.png" alt="" />
                <img v-else src="../../assets/img/cutomer_detail_pro_check.png" alt="" />
                新建产品
              </div>
              <div
                @click="handleAdd_some(1)"
                @mouseover="handleMouseover(6)"
                :class="Mouseover_id == 6 ? 'img_check' : ''"
                @mouseleave="handleLeave"
                v-if="check_self == 1 && !my_customer_service_id"
              >
                <img v-if="Mouseover_id == 6" src="../../assets/img/cutomer_detail_newcant.png" alt="" />
                <img v-else src="../../assets/img/cutomer_detail_newcant_check.png" alt="" />
                新建联系人
              </div>
              <div
                @click="handleabandon"
                @mouseover="handleMouseover(7)"
                :class="Mouseover_id == 7 ? 'img_check' : ''"
                @mouseleave="handleLeave"
                v-if="check_self == 1 && !my_customer_service_id"
              >
                <img v-if="Mouseover_id == 7" src="../../assets/img/cutomer_detail_give.png" alt="" />
                <img v-else src="../../assets/img/cutomer_detail_give_check.png" alt="" />
                放弃
              </div>
              <div
                @click="handleAdd_some(8)"
                @mouseover="handleMouseover(8)"
                :class="Mouseover_id == 8 ? 'img_check' : ''"
                @mouseleave="handleLeave"
              >
                <img v-if="Mouseover_id == 8" style="width: 16px; height: 15px" src="../../assets/img/fq_02.png" alt="" />
                <img v-else style="width: 16px; height: 15px" src="../../assets/img/fq.png" alt="" />
                修改产品
              </div>
            </div>
          </div>
          <div class="cutomer_detail_back pointer" @click="handleBack">返回上一级</div>
        </div>
        <!--<div class="cutomer_detail_info">-->
        <!--<div style="display: flex">-->
        <!--产品意向：-->
        <!--<div-->
        <!--v-for="(item, index) in customerdetail.my_customer_product"-->
        <!--:key="index"-->
        <!--&gt;-->
        <!--{{ item.product_name.product_name }}-->
        <!--&lt;!&ndash;{{
          &ndash;&gt;-- >
          < !-- &lt; ! & ndash;item.status == 2 & ndash;&gt;-- >
          < !-- &lt; ! & ndash;? "已成交" & ndash;&gt;-- >
          < !-- &lt; ! & ndash;: item.intention == 1 & ndash;&gt;-- >
          < !-- &lt; ! & ndash;? "A - " & ndash;&gt;-- >
          < !-- &lt; ! & ndash;: item.intention == 2 & ndash;&gt;-- >
          < !-- &lt; ! & ndash;? "B - " & ndash;&gt;-- >
          < !-- &lt; ! & ndash;: item.intention == 3 & ndash;&gt;-- >
          < !-- &lt; ! & ndash;? "C - " & ndash;&gt;-- >
          < !-- &lt; ! & ndash;: item.intention == 4 & ndash;&gt;-- >
          < !-- &lt; ! & ndash;? "D - " & ndash;&gt;-- >
          < !-- &lt; ! & ndash;: "" & ndash;&gt;-- >
          < !-- &lt; ! & ndash;
        }}{{ item.status == 2 ? "" : item.time + "天" }}&ndash;&gt;-->
        <!--</div>-->
        <!--</div>-->
        <!--<div v-if="customerdetail.customer_res.company_add_time != ''">-->
        <!--成立时间：{{ customerdetail.customer_res.company_add_time }}-->
        <!--</div>-->
        <!--<div>-->
        <!--地址：{{ customerdetail.customer_res.province_name }} - -->
        <!--{{ customerdetail.customer_res.city_name }} - -->
        <!--{{ customerdetail.customer_res.area_name }}-->
        <!--</div>-->
        <!--<div v-if="customerdetail.customer_res.website">-->
        <!--网址：{{ customerdetail.customer_res.website }}-->
        <!--</div>-->
        <!--</div>-->
        <div class="cutomer_detail_info_cont" v-if="customerdetail.customer_res">
          <div>
            <p>
              公司名称：<span>{{ customerdetail.customer_res.name }}</span>
            </p>
            <div
              style="display: flex; align-items: center; width: 90%; flex-wrap: wrap; flex-direction: row"
              v-if="customerdetail.customer_contacts_list"
            >
              <div v-for="item in customerdetail.customer_contacts_list">
                <p>
                  联 系 人：<span>{{ item.contacts }}</span>
                </p>

                <p>
                  电 话：<span>{{ item.phone }}</span>
                </p>
                <p>
                  职 位：<span>{{
                    item.position_id == 1
                      ? "法定代表人"
                      : item.position_id == 2
                      ? "董事长"
                      : item.position_id == 3
                      ? "总经理"
                      : item.position_id == 4
                      ? "老板亲属"
                      : item.position_id == 5
                      ? "关键经办人"
                      : "其他"
                  }}</span>
                </p>
              </div>
            </div>
            <div v-if="customerdetail.customer_contacts">
              <p>
                联 系 人：<span>{{ customerdetail.customer_contacts.contacts }}</span>
              </p>

              <p>
                电 话：<span>{{ customerdetail.customer_contacts.phone }}</span>
              </p>
            </div>
            <p v-if="customerdetail.customer_contacts">
              职 位：<span>{{ customerdetail.customer_res.customer_position.name }}</span>
            </p>
          </div>
          <div>
            <p>
              客户来源：<span v-for="item in sourceList">
                <span v-if="item.value == customerdetail.customer_res.customer_source">{{ item.label }}</span></span
              >
            </p>
            <p>
              地 址：<span
                >{{ customerdetail.customer_res.province_name }} - {{ customerdetail.customer_res.city_name }} -
                {{ customerdetail.customer_res.area_name }}</span
              >
            </p>
            <p>
              成立时间：<span>{{
                customerdetail.customer_res && customerdetail.customer_res.company_add_time
                  ? customerdetail.customer_res.company_add_time
                  : ""
              }}</span>
            </p>
            <p>
              跟 进 人：<span>{{ customerdetail.my_user.name }}</span>
            </p>
          </div>
          
        </div>
        <p style="margin-top: 14px;color: #999;">说明小记：<span style="color: #595959;">{{ customerdetail.customer_res.management_product }}</span></p>
        
      </div>
      <!--table-->
      <div class="cutomer_detail_box">
        <div class="cutomer_detail_box_head" style="margin-bottom: 30px">
          <div
            :class="isCheck == 1 ? 'cutomer_detail_box_head_check pointer' : 'cutomer_detail_box_head_no_check pointer'"
            @click="handleCheck(1)"
          >
            <div>跟进记录</div>
            <div class="cutomer_detail_box_head_border"></div>
          </div>
          <div
            :class="isCheck == 2 ? 'cutomer_detail_box_head_check pointer' : 'cutomer_detail_box_head_no_check pointer'"
            style="margin-left: 110px"
            @click="handleCheck(2)"
          >
            <div>订单列表</div>
            <div class="cutomer_detail_box_head_border"></div>
          </div>
        </div>
        <div class="cutomer_detail_box_information" v-if="isCheck == 2 && customerdetail">
          <el-table
            :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
            :data="tableDatas"
            style="width: 100%"
            border
            fixed
            :show-overflow-tooltip="true"
            :key="Math.random()"
            :default-sort="{ prop: 'date', order: 'descending' }"
          >
            <el-table-column label="订单号" width="200px" fixed>
              <template slot-scope="scope">
                <div class="acc_table_company_name">
                  {{ scope.row.order_number }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="公司名称" fixed>
              <template slot-scope="scope">
                <div class="acc_table_company_name">
                  {{ scope.row.customer ? scope.row.customer.name : "--" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="客户信息">
              <template slot-scope="scope">
                <div class="acc_table_company_name">
                  {{ scope.row.contacts.contacts }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="合同金额" width="250px">
              <template slot-scope="scope">
                <div class="acc_table_company_name" v-if="scope.row.product_name">
                  {{ scope.row.product_name.product_name }}-{{ scope.row.contract_money }}
                </div>
                <div class="acc_table_company_name" v-else>--</div>
              </template>
            </el-table-column>
            <el-table-column label="订单业绩" prop="achievement"> </el-table-column>
            <el-table-column label="订单类型" width="120px">
              <template slot-scope="scope">
                {{ orderType(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column label="收款情况" width="320px">
              <template slot-scope="scope">
                <div v-html="Collection(scope.row.order_data)"></div>
              </template>
            </el-table-column>
            <el-table-column label="应收尾款" prop="accounts_receivable"> </el-table-column>
            <el-table-column label="操作" width="200px">
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <div class="acc_table_company_name pointer" style="color: #3a85fc !important" @click="placeOrder(scope.row)">
                    下单状态
                  </div>
                  <span style="color: #3a85fc; margin: 0 5px">|</span>
                  <div class="acc_table_company_name pointer" style="color: #3a85fc !important" @click="todetail(scope.row)">业绩详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="cutomer_detail_box_Follow" v-if="isCheck == 1">
          <el-table
            :data="follow_List"
            border
            :key="Math.random()"
            :header-cell-style="{ background: '#F5F5F5', color: '#606266' }"
            style="width: 100%"
          >
            <el-table-column label="跟进人" :key="1" prop="name">
              <template slot-scope="scope">
                {{ scope.row.user.name }}
              </template>
            </el-table-column>
            <el-table-column label="跟进时间" :key="1" prop="to_time">
              <template slot-scope="scope">
                {{ scope.row.to_time ? scope.to_time : scope.row.trail_time }}
              </template>
            </el-table-column>
            <el-table-column label="跟进产品" :key="2" prop="to_time">
              <template slot-scope="scope">
                <div v-for="(itm, index) in scope.row.log_product" :key="index">
                  {{ itm.product_plan_name.product_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="促进" :key="7" width="450px">
              <template slot-scope="scope">
                <div class="th">
                  <div class="td">促进人</div>
                  <div class="td">内容</div>
                  <div class="td">时间</div>
                </div>
                <div class="tr" v-for="item in scope.row.comment" :key="item.id">
                  <div class="td">{{ item.user_name.name }}</div>
                  <div class="td">{{ item.comment }}</div>
                  <div class="td">{{ item.created_at }}</div>
                </div>
                <!--  -->
              </template>
            </el-table-column>
            <el-table-column label="跟进小结" :key="5" prop="result">
              <template slot-scope="scope">
                <el-popover placement="top-start" width="400" trigger="hover" :content="scope.row.result">
                  <div class="acc_table_plan" slot="reference">
                    {{ scope.row.result }}
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="操作" :key="9">
              <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.user_id != user_id" @click="show_commen(scope.row)">促进</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="cutomer_detail_box op-btns" style="display: flex">
        <div>
          <h1>客户标星</h1>

          <div class="cutomer_detail_info_cont">
            <img
              :src="
                customerdetail && customerdetail.is_star == '2'
                  ? require('../../assets/img/xing01.png')
                  : require('../../assets/img/xing02.png')
              "
              class="wxing"
              @click="onXing"
            />
          </div>
        </div>

        <div v-has="'Marketing:customer_whitelist'" style="margin-left: 4em">
          <h1>白名单</h1>
          <img
            :class="{ gray: customerdetail?.is_white_list !== 2 }"
            style="margin: 0 0 0 0.5em"
            :src="require('../../assets/img/white_list.svg')"
            alt=""
            @click="onWhileList"
          />
        </div>
      </div>

      <div class="cutomer_detail_box">
        <h1>跟进产品</h1>
        <div class="cutomer_detail_info_cont">
          <div class="prod-item" v-for="(item, index) in my_customer_product" :key="index">
            <p>
              产品名称：<span>{{ item.product_name.product_name }}</span>
            </p>
            <p>
              备注：<span>{{ item.customer_analysis }}</span>
            </p>
          </div>
        </div>
      </div>
      <!--数据统计-->
      <div class="cutomer_detail_box">
        <h1>数据统计</h1>
        <div class="cutomer_detail_Statistics">
          <div v-if="customerdetail && customerdetail.statistics">
            <h2>跟进统计</h2>
            <p>
              电话跟进：<span>{{ customerdetail.statistics.phone_total }}</span>
            </p>
            <p>
              微信跟进：<span>{{ customerdetail.statistics.wechat_total }}</span>
            </p>
            <p>
              面 访：<span>{{ customerdetail.statistics.visit_total }}</span>
            </p>
            <p>
              总 跟 进：<span>{{ customerdetail.statistics.total }}</span>
            </p>
          </div>
          <div v-if="customerdetail && customerdetail.order_statistics">
            <h2>合同信息</h2>
            <p>
              总 合 同 金 额：<span>￥{{ customerdetail.order_statistics.contract_money }}</span>
            </p>
            <p>
              应收合同余款：<span style="color: red">￥{{ customerdetail.order_statistics.accounts_receivable_contract }}</span>
            </p>
          </div>
          <div v-if="customerdetail && customerdetail.order_statistics">
            <h2>订单统计</h2>
            <p>
              订单总数：<span>{{ customerdetail.order_statistics.order_total }}</span>
            </p>
            <p>
              续签次数：<span>{{ customerdetail.order_statistics.renewal_total }}</span>
            </p>
            <p>
              到账频率：<span>{{ customerdetail.order_statistics.arrival_frequency }}</span>
            </p>
          </div>
          <div v-if="customerdetail && customerdetail.order_statistics">
            <h2>收款统计</h2>
            <p>
              订单全款总额：<span>￥{{ customerdetail.order_statistics.total_amount_receivable }}</span>
            </p>
            <p>
              实 收 总 额：<span>￥{{ customerdetail.order_statistics.achievement }}</span>
            </p>
            <p>
              应收尾款总额：<span style="color: red">￥{{ customerdetail.order_statistics.accounts_receivable }}</span>
            </p>
            <p>
              首 签 时 间：<span>{{ customerdetail.order_statistics.first_sign_time }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="login_mc" v-if="isMore"></div>
      <div class="add_box" v-if="isAddcontact" :style="add_what == 1 ? 'height: 45%' : add_what == 2 ? 'height: 35%' : 'height: 60%'">
        <div class="add_box_head">
          {{ add_what == 1 ? "添加联系人" : add_what == 2 ? "添加产品" : add_what == 8 ? "修改产品" : "" }}
          <i class="el-icon-close" @click="handleAdd_NO"></i>
        </div>
        <div class="add_box_head_tit" v-if="add_what == 1"><i></i>客户信息</div>
        <div class="add_box_info">
          <div v-if="add_what == 1">
            <div class="add_box_info_line">
              <div class="add_box_info_line_tit">公司名称：</div>
              <div class="add_box_info_line_box">
                {{ customerdetail.customer_res ? customerdetail.customer_res.name : "--" }}
              </div>
            </div>
            <div class="add_box_info_line">
              <div class="add_box_info_line_tit">联系人：</div>
              <el-input v-model="add_contact" placeholder="请输入姓名"></el-input>
            </div>
            <div class="add_box_info_line">
              <div class="add_box_info_line_tit">职位：</div>
              <el-select v-model="position_id" placeholder="请选择职位">
                <el-option v-for="item in positionList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
            <div class="add_box_info_line">
              <div class="add_box_info_line_tit">电话：</div>
              <el-input v-model="add_phone" placeholder="请输入电话" maxlength="11"></el-input>
            </div>
          </div>
          <div v-if="add_what == 2">
            <div class="add_box_info_line">
              <div class="add_box_info_line_tit">产品名称：</div>
              <el-cascader v-model="product_name" :options="prooptions" placeholder="请选择产品" separator=" - "> </el-cascader>
            </div>
            <!--<div class="add_box_info_line">-->
            <!--<div class="add_box_info_line_tit">产品意向：</div>-->
            <!--<el-select v-model="intention" placeholder="请选择产品意向">-->
            <!--<el-option-->
            <!--v-for="item in intentionList"-->
            <!--:key="item.value"-->
            <!--:label="item.label"-->
            <!--:value="item.value"-->
            <!--&gt;-->
            <!--</el-option>-->
            <!--</el-select>-->
            <!--</div>-->
            <div class="add_box_info_line" style="align-items: flex-start; min-height: 32px">
              <div class="add_box_info_line_tit">备注：</div>
              <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="customer_analysis"> </el-input>
            </div>
          </div>
          <div v-if="add_what == 8" class="dels">
            <div v-for="item in my_customer_product">
              <div style="display: flex; align-items: flex-start">
                <i
                  class="el-icon-remove-outline"
                  style="font-size: 24px; color: #dbdbdb; margin-top: 25px"
                  @click.prevent="remove(item)"
                ></i>
                <div style="margin-left: 20px; width: 100%">
                  <div class="add_box_info_line" style="margin-bottom: 0px">
                    <div class="add_box_info_line_tit">产品名称：</div>
                    <input v-model="item.product_name.product_name" />
                  </div>
                  <div class="add_box_info_line" style="align-items: flex-start; min-height: 32px; margin-top: 10px">
                    <div class="add_box_info_line_tit">备注：</div>
                    <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="item.customer_analysis"> </el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add_box_bottom">
          <div @click="handleAdd_NO">取消</div>
          <div @click="hanldeAdd_OK">确定</div>
        </div>
      </div>
      <div class="edit_box_big" v-if="isEdit">
        <div class="edit_box">
          <!-- 编辑客户信息 -->
          <div class="add_cutomer_head">
            编辑客户
            <i class="pointer el-icon-close" style="padding: 10px" @click="handleClose"></i>
          </div>
          <div class="add_cutomer_box">
            <div class="add_cutomer_box_head"><i></i>客户信息</div>
            <div class="add_cutomer_box_info">
              <div class="add_cutomer_box_info_tit"><i>*</i> 公司名称：</div>
              <el-input v-model="company_name" placeholder="请输入公司名称"></el-input>
            </div>
            <div class="add_cutomer_box_contacts">
              <div class="add_cutomer_box_info">
                <div class="add_cutomer_box_info_tit"><i>*</i> 联系人：</div>
                <el-input v-model="contact" placeholder="请输入联系人名称"></el-input>
              </div>
              <div class="add_cutomer_box_info">
                <div class="add_cutomer_box_info_tit"><i>*</i> 职位：</div>
                <el-select v-model="position" placeholder="请选择职位">
                  <el-option v-for="item in positionList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </div>
              <div class="add_cutomer_box_info">
                <div class="add_cutomer_box_info_tit"><i>*</i>电话：</div>
                <el-input v-model="phone" placeholder="请输入联系人电话" maxlength="11"></el-input>
              </div>
            </div>
            <div class="add_cutomer_box_info">
              <div class="add_cutomer_box_info_tit"><i>*</i> 客户来源：</div>
              <el-select v-model="source" placeholder="请选择客户来源">
                <el-option v-for="item in sourceList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div class="add_cutomer_box_info">
              <div class="add_cutomer_box_info_tit"><i style="color: #fff">*</i> 成立时间：</div>
              <el-date-picker
                v-model="time"
                type="date"
                prefix-icon="el-icon-date"
                clear-icon="sss"
                :picker-options="pickerOptions"
                placeholder="请选择成立时间"
              >
              </el-date-picker>
            </div>
            <div class="add_cutomer_box_info">
              <div class="add_cutomer_box_info_tit"><i>*</i> 公司地址：</div>
              <el-cascader
                v-model="city_name_id"
                :props="{
                  label: 'name',
                  value: 'code',
                  children: 'all_children',
                }" filterable
                :options="cityList"
                separator=" - "
                ref="addressCascader"
                placeholder="请选择省市区"
                @change="handleChange($event, 1)"
              >
              </el-cascader>
            </div>
            <div class="add_cutomer_box_info">
              <div class="add_cutomer_box_info_tit"><i></i> 详细地址：</div>
              <el-input v-model="company_address" placeholder="请输入详细地址" @focus="showBMap"></el-input>
            </div>

            <Bmap v-if="is_map" @closeMap="closeMap" :lng="lng" :lat="lat" />
            <div class="add_cutomer_box_info">
              <div class="add_cutomer_box_info_tit"><i style="color: #fff">*</i> 注册资本：</div>
              <el-input v-model="registered_capital" placeholder="请输入注册资本（万元）"></el-input>
            </div>
            <div class="add_cutomer_box_info">
              <div class="add_cutomer_box_info_tit"><i style="color: #fff">*</i> 网址：</div>
              <el-input v-model="website" placeholder="请输入网址"></el-input>
            </div>
            <div class="add_cutomer_box_info">
              <div class="add_cutomer_box_info_tit"><i style="color: #fff">*</i> 客户微信主页图片：</div>
              <!-- <el-input v-model="wechat" placeholder="请输入微信号"></el-input> -->
              <div class="flow_img_listinfo">
                <div class="img_item_info" v-for="(item, index) in img_list" :key="index">
                  <img :src="item.file_url" alt="" class="big_img pointer" @click="take_big(item.file_url)" />
                  <i class="el-icon-error del_img pointer" @click="del_img(index)"></i>
                </div>
                <div class="image_info pointer" @click="chooseFile" v-if="img_list.length < 1">
                  <img src="@/assets/img/upload_img.png" alt="" style="width: 28px; height: 25px" />
                  <span>请上传图片</span>
                  <input type="file" style="display: none" ref="choosePhoto" @change="photoChange($event)" id="upload_file" />
                </div>
              </div>
            </div>
            <div class="add_cutomer_box_info" style="align-items: flex-start">
              <div class="add_cutomer_box_info_tit"><i>*</i> 经营产品：</div>
              <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" placeholder="请输入经营产品" v-model="management_product">
              </el-input>
            </div>
            <div class="add_cutomer_box_bottom">
              <div @click="handleClose">取消</div>
              <div @click="handleAdd">确定</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 促销申请 -->
      <div class="add_cx_bigbox" v-if="is_CX">
        <div class="add_cx_box">
          <div class="add_box_head">
            促销申请
            <i class="el-icon-close pointer" @click="handleclose_Apply" style="pading: 10px"></i>
          </div>
          <div class="add_cx_box_info">
            <div class="add_cx_box_head"><i></i>公司信息</div>
            <div class="add_cx_box_customer">
              <div class="add_cx_box_customer_line">
                <div class="add_cx_box_customer_line_tit">公司名称：</div>
                <div class="add_cx_box_customer_line_int">
                  {{ customerdetail.customer_res ? customerdetail.customer_res.name : "--" }}
                </div>
              </div>
              <div class="add_cx_box_customer_line">
                <div class="add_cx_box_customer_line_tit">客户姓名：</div>
                <div class="add_cx_box_customer_line_int">
                  {{ customerdetail.customer_contacts_list[0].contacts }}
                </div>
              </div>
              <div class="add_cx_box_customer_line">
                <div class="add_cx_box_customer_line_tit">电话：</div>
                <div class="add_cx_box_customer_line_int">
                  {{ customerdetail.customer_contacts_list[0].phone }}
                </div>
              </div>
            </div>
            <div class="add_cx_box_head"><i></i>申请信息</div>
            <div class="add_cx_box_customer">
              <div class="add_cx_box_customer_line">
                <div class="add_cx_box_customer_line_tit">产品类型：</div>
                <el-select v-model="cx_proname_id" placeholder="请选择促销产品">
                  <el-option v-for="item in cx_prooption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>

              <div class="add_cx_box_customer_line">
                <div class="add_cx_box_customer_line_tit">促销类型</div>
                <el-select v-model="cx_typeid" placeholder="请选择促销类型">
                  <el-option v-for="item in cx_arr" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
              <div class="add_cx_box_customer_line" v-if="cx_typeid == 4">
                <div class="add_cx_box_customer_line_tit">服务时长：</div>
                <el-date-picker
                  v-model="time_section"
                  type="daterange"
                  prefix-icon="sdal"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
              <div class="add_cx_box_customer_line" style="align-items: flex-start; height: 84px" v-if="cx_typeid == 5">
                <div class="add_cx_box_customer_line_tit">赠送服务：</div>
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请输入内容" v-model="service_info">
                </el-input>
              </div>
              <div class="add_cx_box_customer_line">
                <div class="add_cx_box_customer_line_tit">促销成本：</div>
                <el-input type="text" v-model="apply_money" placeholder="请输入促销成本" />
              </div>
              <div class="add_cx_box_customer_line">
                <div class="add_cx_box_customer_line_tit">政策类型</div>
                <el-select v-model="cx_zc_typeid" placeholder="请选择政策类型">
                  <el-option v-for="item in cx_zc_type" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
              <div class="add_cx_box_customer_line" style="align-items: flex-start">
                <div class="add_cx_box_customer_line_tit">申请说明：</div>
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" placeholder="请输入内容" v-model="apply_explain">
                </el-input>
              </div>
            </div>
            <div class="add_cx_box_head" style="margin-top: 80px"><i></i>抄送人</div>
            <div class="add_cx_box_customer">
              <div class="add_cx_box_customer_line" style="padding: 10px 0px">
                <div class="add_cx_box_customer_line_tit" style="white-space: nowrap">
                  {{ apply_chaosong ? apply_chaosong.name : "" }}
                </div>
                <div class="add_cx_box_customer_line_img">
                  <img src="../../assets/img/more_head.png" alt="" />
                  <!-- <i class="el-icon-arrow-right"></i>
                  <img :src="user.head_portrait" alt="" /> -->
                </div>
              </div>
            </div>
            <div class="add_cx_box_bottom">
              <div @click="handleclose_Apply">取消</div>
              <div @click="handle_apply">确认</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 新增计划 -->
      <div class="info_add" v-if="show_flag">
        <div class="take_new_title">
          新增计划
          <i
            class="el-icon-close pointer"
            style="color: #999999"
            @click="
              show_flag = false;
              isMore = false;
            "
          ></i>
        </div>
        <div class="take_new_detail">
          <div class="acc_top_compony">
            <div class="kf_detail">
              <div class="quan"></div>
              客户信息
            </div>
            <div class="kh_detail">
              <div style="margin-top: 0">公司名称：{{ customerdetail.customer_res ? customerdetail.customer_res.name : "" }}</div>
              <div style="margin-top: 0">客户名称：{{ customerdetail.customer_contacts_list[0].contacts }}</div>
              <div>手机号：{{ customerdetail.customer_contacts_list[0].phone }}</div>
              <div>产品意向：{{ flow_pro }}</div>
            </div>
            <div class="get_detail" style="padding-left: 11px">
              <div class="flow_kind">
                跟进方式：
                <el-select v-model="flow_kind" placeholder="请选择">
                  <el-option v-for="item in prodect_list" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </div>
              <div class="flow_kind">
                跟进日期：
                <el-date-picker v-model="check_time" type="datetime" placeholder="选择日期时间" :picker-options="pickerOptionss">
                </el-date-picker>
              </div>
              <div class="flow_kind_text">
                跟进计划：
                <el-input type="textarea" :rows="3" placeholder="请输入跟进计划" v-model="flow_plan"> </el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="take_bottom">
          <div class="bottom_sent pointer" @click="sent_new_plan">保存</div>
        </div>
      </div>
      <AddArrival ref="my_AddArrival" @close="handleClose_AddArrival" :detail="customerdetail" v-if="isAddArrival" />

      <!-- 填写结果 -->
      <div class="acc_shadow" v-if="notes_show">
        <div class="info">
          <div class="take_new_title">
            填写跟进结果
            <i class="el-icon-close pointer" style="color: #999999" @click="(notes_show = false), (isMore = false)"></i>
          </div>
          <div class="take_new_detail">
            <div class="acc_top_compony">
              <div class="kf_detail">
                <div class="quan"></div>
                客户信息
              </div>
              <div class="kh_detail" v-if="customerdetail.customer_res">
                <div>公司名称：{{ customerdetail.customer_res.name }}</div>
                <div>客户名称：{{ customerdetail.customer_res.contacts }}</div>
                <div>手机号：{{ customerdetail.customer_res.phone }}</div>
                <div>
                  产品意向：<span v-for="(item, index) in customerdetail.my_customer_product" :key="index" style="margin-right: 10px"
                    >{{ item.product_name.product_name }}
                    <!-- - -->
                    <!--{{-->
                    <!--item.status == 2-->
                    <!--? "已成交"-->
                    <!--: item.intention == 1-->
                    <!--? "A - "-->
                    <!--: item.intention == 2-->
                    <!--? "B - "-->
                    <!--: item.intention == 3-->
                    <!--? "C - "-->
                    <!--: "D - "-->
                    <!--}}<span v-if="item.status != 2">{{-->
                    <!--item.time + "天"-->
                    <!--}}</span>-->
                  </span>
                </div>
              </div>
              <div class="get_detail">
                <div class="flow_kind">
                  跟进方式：{{
                    info_detail.status == 1
                      ? "电话"
                      : info_detail.status == 2
                      ? "微信"
                      : info_detail.status == 3
                      ? "出访"
                      : info_detail.status == 4
                      ? "来访"
                      : "其他"
                  }}
                </div>

                <div class="flow_kind_text" style="width: 100%; overflow: didden; white-space: nowrap; text-overflow: ellipsis">
                  跟进计划：
                  <div style="white-space: initial">{{ info_detail.plan }}</div>
                </div>
                <div class="flow_kind">
                  跟进时间：
                  <el-date-picker
                    v-model="flow_time"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
                <div class="flow_kind" v-if="info_detail.status == 3">
                  是否陪访：
                  <el-radio v-model="is_tegether" label="1">是</el-radio>
                  <el-radio v-model="is_tegether" label="2">否</el-radio>
                </div>
                <div class="flow_kind" v-if="is_tegether == 1">
                  <span style="margin-right: 14px">陪访人：</span>
                  <el-select v-model="flow_human" placeholder="请选择">
                    <el-option v-for="item in flow_human_list" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                </div>
                <div class="flow_kind" v-if="is_tegether == 1">
                  <span style="margin-left: -14px">陪访人姓名：</span>
                  <el-input placeholder="请输入陪访人姓名" v-model="tegether_name"> </el-input>
                </div>
                <div class="flow_kind" v-if="info_detail.status == 3 || info_detail.status == 4">
                  <span>见面目的：</span>
                  <el-select v-model="objective" placeholder="请选择">
                    <el-option v-for="item in objective_list" :key="item.name" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                </div>
                <div class="flow_kind" v-if="info_detail.status == 3">
                  <span style="margin-left: 28px">地址：</span>
                  <el-input placeholder="请输入地址" v-model="address"> </el-input>
                </div>
                <div class="flow_kind_text">
                  <span style="margin-top: 6px; white-space: nowrap">跟进结果：</span>
                  <el-input type="textarea" placeholder="请输入跟进结果" v-model="plan_end" rows="3" maxlength="1000" show-word-limit>
                  </el-input>
                </div>
                <div class="flow_kind_text" v-if="info_detail.status == 2 || info_detail.status == 3 || info_detail.status == 4">
                  <span style="margin-top: 6px">图片展示：</span>
                  <div class="flow_img_listinfo">
                    <div class="img_item_info" v-for="(item, index) in img_list" :key="index">
                      <img :src="item.file_url" alt="" class="big_img pointer" @click="take_big(item.file_url)" />
                      <i class="el-icon-error del_img pointer" style="color: #ccc" @click="del_img(index)"></i>
                    </div>
                    <div class="image_info pointer" @click="chooseFile" v-if="img_list.length < 1">
                      <img src="@/assets/img/upload_img.png" alt="" style="width: 28px; height: 25px" />
                      <span>请上传图片</span>
                      <input type="file" style="display: none" ref="choosePhoto" @change="photoChange($event)" id="upload_file" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="take_bottom">
            <div class="bottom_sent pointer" @click="sent_flow">保存</div>
          </div>
        </div>
      </div>
      <!-- 放大图片 -->
      <div class="acc_img_shadow" v-if="big_imgshow" @click="big_imgshow = false">
        <img :src="imgsrc" alt="" />
      </div>
      <!--下单状态-->
      <el-dialog title="下单状态" :visible.sync="dialogVisible" width="20%" top="15%">
        <div>
          <el-radio v-model="radio" label="0">未下单</el-radio>
          <el-radio v-model="radio" label="1">已下单</el-radio>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogOk">确 定</el-button>
        </span>
      </el-dialog>
    </el-main>
    <div class="acc_shadow" v-if="commen_show">
      <div class="acc_commen">
        <div class="acc_commen_title">
          促进
          <i class="el-icon-close pointer" style="color: #999999" @click="commen_show = false"></i>
        </div>
        <div class="acc_commen_body">
          <el-input type="textarea" :rows="3" maxlength="150" placeholder="请输入促进内容" v-model="commen"> </el-input>
        </div>
        <div class="acc_commen_sent pointer" @click="sent_commen">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  detail,
  followList,
  abandon,
  getPosition,
  addContacts,
  getProduct,
  getCityList,
  editCustomer,
  addPlan,
  getPromotionVerify,
  addPromotion,
  addPlanLog,
  addProduct,
  addComment,
  editProducts,
  backLibrary,
  myClientsStar,
  myClientWhite,
} from "../../api/User/customer";
import { list, editOrdertype } from "../../api/User/order_list";
import { uploadFile } from "../../api/User/index";
import Add_Arrival from "./Add_Arrival";
import Bmap from "./Bmap";

export default {
  components: {
    AddArrival: Add_Arrival,
    Bmap: Bmap,
  },
  data() {
    return {
      order_id: "",
      radio: "",
      dialogVisible: false,
      lng: "", //传入map
      lat: "", //传入map
      type: 1, //1  个人 2 部门
      isMore: false, //蒙层
      isAddcontact: false,
      isEdit: false, //编辑客户信息
      is_CX: false, //促销
      add_what: "", //1 添加联系人 2 添加产品
      isCheck: 1, //客户信息切换
      isAddArrival: false, //新增到账
      show_flag: false, //新增计划
      customerdetail: null, //客户详情
      customer_contacts: [], //联系人
      my_customer_product: [], //客户产品
      follow_List: [], //计划
      my_customer_id: "", //客户id
      add_contact: "", //新增联系人 姓名
      add_phone: "", //新增联系人 电话
      position_id: "", //新增联系人职位
      positionList: [],
      prooptions: [],
      product_name: null,
      departmentList: [], //部门的时候
      intentionList: [
        { value: "1", label: "A" },
        { value: "2", label: "B" },
        // { value: "3", label: "C" },
        // { value: "4", label: "D" },
      ],
      intention: "", //单个产品意向
      customer_analysis: "", //客户分析
      tableData: [], //部门 客户详情
      Mouseover_id: "0", //鼠标移入
      // 编辑客户
      is_map: false,
      company_name: "", //公司名称
      contact: "", //联系人
      position: "", //编辑职位id
      phone: "",
      other_contact: [], //其他联系人
      time: "", //成立时间
      management_product: "", //客户情况
      wechat: [],
      cityList: [], // 地址
      city_name_id: [],
      province_name: "", //省名称
      city_name: "", //市名称
      area_name: "", //区名称
      company_address: "", //公司详细地址
      longitude: "",
      latitude: "",

      sourceList: [
        { value: 1, label: "网络查找" },
        { value: 2, label: "阿里资源库" },
        { value: 3, label: "人脉介绍" },
        { value: 4, label: "老客户转介绍" },
        { value: 5, label: "陌生面访" },
        // { value: 6, label: "其他" },
      ],
      source: "", //客户来源
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pickerOptionss: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      registered_capital: "", //注册资金
      website: "", //网址

      //促销申请
      cx_prooption: [], //申请促销产品列表
      cx_proname_id: "", //促销id
      cx_arr: [
        { value: 1, label: "实物" },
        { value: 2, label: "返现" },
        { value: 3, label: "打折" },
        { value: 4, label: "赠送服务时长" },
        { value: 5, label: "赠送其它服务" },
        // { value: 6, label: "其他" },
      ], //促销类型
      cx_typeid: "",
      apply_money: "", //促销金额
      apply_explain: "", //促销说明
      apply_chaosong: null, //促销申请抄送人
      cx_zc_type: [
        { value: 1, label: "政策性促销" },
        { value: 2, label: "非政策性促销" },
      ],
      cx_zc_typeid: "", //政策类型
      time_section: "", //服务区间
      service_info: "", //服务详情
      //新增计划
      prodect_list: [
        { id: 1, name: "电话" },
        { id: 2, name: "微信" },
        { id: 3, name: "出访" },
        { id: 4, name: "来访" },
        { id: 5, name: "其他" },
      ],
      flow_kind: "", //计划方式
      check_time: "", //计划时间
      flow_plan: "", //计划详情
      flow_pro: "", //新增计划产品

      //填写跟进结果
      notes_show: false,
      plan_end: "", //跟进结果
      img_list: [],
      info_detail: null,
      imgsrc: "", // 放大图片路径
      big_imgshow: false, //放大图片显示隐藏
      flow_time: "", //跟进时间
      is_tegether: "2", //是否配方人
      flow_human: "", //跟进
      flow_human_list: [
        { id: 1, name: "经理陪访" },
        { id: 2, name: "其他陪访" },
      ], //跟进人职位陪访
      tegether_name: "", //配方人姓名
      objective: "",
      objective_list: [
        { id: 1, name: "洽谈签约" },
        { id: 2, name: "客户服务" },
        { id: 3, name: "要转介绍" },
        { id: 4, name: "邀请参会" },
        { id: 5, name: "送达礼物" },
        { id: 6, name: "其它" },
      ],
      address: "",
      add_flag: false,
      check_self: 1,
      is_gh: 2,
      tableDatas: [],
      row: {},
      commen_show: false,
      commen: "",
      user_id: "",
    };
  },
  computed: {
    orderType: function () {
      return function (row) {
        let option1 = row.cooperation == 1 ? "首签" : "续签";
        let option2 = row.type == 0 ? "未下单" : "已下单";
        return option1 + "-" + option2;
      };
    },
    Collection: function () {
      return function (row) {
        let str = "";
        row.forEach((item) => {
          let type = item.type == 1 ? "定金" : item.type == 2 ? "尾款" : "全款";
          str += `<div>${item.to_time} - ${type} - ${item.to_money}</div>`;
        });

        return str;
      };
    },
  },
  created() {
    let obj = {};
    this.user_id = JSON.parse(localStorage.getItem("user")).user_id;
    this.my_customer_service_id = "";
    if (this.$route.params.type) {
      obj = this.$route.params;
      if (obj.check_self) {
        this.check_self = obj.check_self;
      } else {
        this.check_self = 1;
      }
      this.my_customer_id = this.$route.params.my_customer_id;
      localStorage.setItem("detail_id", JSON.stringify(this.$route.params));
    } else {
      obj = JSON.parse(localStorage.getItem("detail_id"));
      if (obj.check_self) {
        this.check_self = obj.check_self;
      } else {
        this.check_self = 1;
      }
      this.my_customer_id = JSON.parse(localStorage.getItem("detail_id")).my_customer_id;
    }
    let positionList = [];
    getCityList({ type: 1 }).then((res) => {
      let list = this.isHasChildren(res.data.data);
      this.cityList = list;
    });
    getPosition().then((res) => {
      positionList = res.data.data;
    });
    let type = obj.type;
    this.type = type;
    let data = {};
    if (obj.my_customer_service_id) {
      this.my_customer_service_id = obj.my_customer_service_id;
      data = {
        type: obj.type,
        my_customer_id: obj.my_customer_id,
        customer_id: obj.customer_id,
        my_customer_service_id: obj.my_customer_service_id,
      };
    } else {
      data = {
        type: obj.type,
        my_customer_id: obj.my_customer_id,
        customer_id: obj.customer_id,
        customer_type: this.$route.params.customer_type ? 2 : 1,
      };
    }
    if (this.$route.params.is_gh) {
      this.is_gh = this.$route.params.is_gh;
    }

    detail(data).then((res) => {
      console.log("detail", res);
      if (res.data.code == 200) {
        console.log(type);
        if (type == 1) {
          this.customerdetail = res.data.data;
          this.customer_contacts = res.data.data.customer_contacts_list;
          this.my_customer_product = res.data.data.my_customer_product;

          let imgs = res.data.data.wechat;
          this.wechat = imgs ? JSON.parse(imgs) : "";
          console.log(this.customer_contacts, this.my_customer_product, this.wechat);
        }
        if (type == 2) {
          this.customerdetail = res.data.data[0];
          this.my_customer_product = res.data.data[0].my_customer_product;
          let list = res.data.data;
          list.forEach((item) => {
            positionList.forEach((itm) => {
              if (item.customer_contacts.position_id == itm.id) {
                item.customer_contacts.position_name = itm.name;
              }
            });
          });
          this.departmentList = list;
        }
        this.city_name_id = [
          String(res.data.data.customer_res["province_id"]),
          String(res.data.data.customer_res["city_id"]),
          String(res.data.data.customer_res["area_id"]),
        ];
      }
    });

    let dataD = {};
    if (this.my_customer_service_id) {
      dataD = {
        my_customer_id: this.my_customer_id,
        follow_type: 2,
      };
    } else {
      dataD = {
        my_customer_id: this.my_customer_id,
      };
    }
    followList(dataD).then((res) => {
      let listS = res.data.data.data;
      this.follow_List = listS;
    });
  },
  methods: {
    isHasChildren(arr) {
      for (let i in arr) {
        if (arr[i].all_children.length != 0) {
          this.isHasChildren(arr[i].all_children);
        } else {
          arr[i].all_children = null;
        }
      }
      return arr;
    },
    // 显示bmap
    showBMap() {
      this.is_map = true;
      let clearTimeOut = setInterval(() => {
        if (document.getElementById("baidu_jsapi_watermark")) {
          document.querySelector("#baidu_jsapi_watermark").parentElement.removeChild(document.querySelector("#baidu_jsapi_watermark"));
          clearInterval(clearTimeOut);
        }
      });
    },
    handleBack() {
      //返回上一级
      this.$router.back();
    },
    handleMouseover(i) {
      this.Mouseover_id = i;
    },
    handleLeave() {
      this.Mouseover_id = 0;
    },
    getwaytime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y + M + D;
      // let strDate = Y + M + D + h + m + '00';
      return strDate;
    },
    getwaytime_s(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let strDate = Y + M + D;
      let strDate = Y + M + D + h + m + "00";
      return strDate;
    },
    handleCheck(i) {
      //客户信息和跟进记录切换
      this.isCheck = i;
      if (i == 2) {
        this.getList();
        // if(this.type==1)
        // {
        //
        // }         else{
        //     this.getList('3')
        // }
      }
    },
    //订单类别
    getList() {
      list({
        class_type: 3,
        my_customer_id: this.my_customer_id,
        serach_user_id: this.customerdetail.user_id,
        customer_id: this.customerdetail.customer_id,
      }).then((res) => {
        this.total = res.data.data.total;
        this.tableDatas = res.data.data.data;
      });
    },
    handleabandon() {
      //放弃跟进
      this.$confirm("放弃客户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          abandon({ my_customer_id: this.my_customer_id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              setTimeout(() => {
                history.go(-1);
              }, 1000);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleAdd_some(i) {
      this.add_what = i;
      this.isMore = true;
      if (i == 1) {
        //新增联系人
        (this.add_contact = ""), (this.add_phone = ""), (this.position_id = ""), (this.isAddcontact = true);
        getPosition().then((res) => {
          if (res.data.code == 200) {
            this.positionList = res.data.data;
          }
        });
      }
      if (i == 2) {
        //新增  产品

        this.product_name = [];
        this.intention = "";
        this.customer_analysis = "";
        this.isAddcontact = true;
        let data = {
          name: this.customerdetail.customer_res.name,
          phone: this.customerdetail.customer_res.phone,
        };
        getProduct(data).then((res) => {
          if (res.data.code == 200) {
            let list = res.data.data;
            for (let i = 0; i < list.length; i++) {
              list[i].label = list[i].class_name;
              list[i].value = list[i].id;
              list[i].children = list[i].product_name;
              for (let j = 0; j < list[i].product_name.length; j++) {
                list[i].product_name[j].label = list[i].product_name[j].product_name;
                list[i].product_name[j].value = list[i].product_name[j].id;
              }
            }
            this.prooptions = list;
          }
        });
      }
      if (i == 3) {
        //编辑

        let { customerdetail } = this;
        this.isEdit = true;
        // console.log(customerdetail);
        this.lng = customerdetail.customer_res ? customerdetail.customer_res.longitude : "";
        this.lat = customerdetail.customer_res ? customerdetail.customer_res.latitude : "";
        if (this.lng) {
          this.is_map = true;
          let clearTimeOut = setInterval(() => {
            if (document.getElementById("baidu_jsapi_watermark")) {
              document.querySelector("#baidu_jsapi_watermark").parentElement.removeChild(document.querySelector("#baidu_jsapi_watermark"));
              clearInterval(clearTimeOut);
            }
          });
        }
        console.log("ddd");
        getPosition().then((res) => {
          console.log("000000", res);
          if (res.data.code == 200) {
            this.positionList = res.data.data;
            this.company_name = customerdetail.customer_res ? customerdetail.customer_res.name : "";
            this.contact = customerdetail.customer_contacts_list[0].contacts;
            this.phone = customerdetail.customer_contacts_list[0].phone;
            this.position = customerdetail.customer_contacts_list[0].position_id;
            this.source = customerdetail.customer_res ? customerdetail.customer_res.customer_source : "";
            this.website = customerdetail.customer_res ? customerdetail.customer_res.website : "";
            this.management_product = customerdetail.customer_res ? customerdetail.customer_res.management_product : "";
            this.wechat = this.isJSON(customerdetail.wechat) ? JSON.parse(customerdetail.wechat) : "";
            this.img_list = this.isJSON(customerdetail.wechat) ? JSON.parse(customerdetail.wechat) : [];
            this.registered_capital = customerdetail.customer_res ? customerdetail.customer_res.registered_capital : "";
            this.province_name = customerdetail.customer_res ? customerdetail.customer_res.province_name : "";
            this.city_name = customerdetail.customer_res ? customerdetail.customer_res.city_name : "";
            this.area_name = customerdetail.customer_res ? customerdetail.customer_res.area_name : "";
            this.city_name_id.push(customerdetail.customer_res ? customerdetail.customer_res.province_id : "");
            this.city_name_id.push(customerdetail.customer_res ? customerdetail.customer_res.city_id : "");
            this.city_name_id.push(customerdetail.customer_res ? customerdetail.customer_res.area_id : "");
            this.company_address = customerdetail.customer_res ? customerdetail.customer_res.address : "";
            this.latitude = customerdetail.customer_res ? customerdetail.customer_res.latitude : "";
            this.longitude = customerdetail.customer_res ? customerdetail.customer_res.longitude : "";
            this.time = customerdetail.customer_res ? customerdetail.customer_res.company_add_time : "";
          }
        });
      }
      if (i == 4) {
        //促销
        this.is_CX = true;
        let { customerdetail } = this;
        let list = customerdetail.my_customer_product;
        list.forEach((item) => {
          item.label = item.product_name.product_name;
          item.value = item.product_name_id;
        });
        getPromotionVerify().then((res) => {
          // console.log(res);
          this.apply_chaosong = res.data.data;
        });
        this.cx_prooption = list;
        this.cx_zc_typeid = "";
      }
      if (i == 5) {
        this.isAddArrival = true;
      }
      if (i == 6) {
        this.flow_kind = "";
        this.check_time = "";
        this.flow_plan = "";
        let { customerdetail } = this;
        let text = "";
        let arr = [];
        customerdetail.my_customer_product.forEach((item) => {
          if (item.status == 2) {
            text = " - 已成交";
          } else {
            text =
              item.intention == 1
                ? " - A - " + item.time + "天"
                : item.intention == 2
                ? " - B - " + item.time + "天"
                : item.intention == 3
                ? " - C - " + item.time + "天"
                : item.intention == 4
                ? " - D - " + item.time + "天"
                : "";
          }
          arr.push(item.product_name.product_name + text);
          this.flow_pro = arr.join("、");
        });
        this.show_flag = true;
      }
      if (i == 8) {
        this.isAddcontact = true;
      }
    },
    //是否是json
    isJSON(str) {
      if (typeof str == "string") {
        try {
          var obj = JSON.parse(str);
          if (typeof obj == "object" && obj) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      } else if (typeof str == "object" && str) {
        return true;
      }
    },
    //放弃
    remove(item) {
      this.$confirm("放弃产品客户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          backLibrary({
            my_customer_id: item.my_customer_id,
            customer_product_id: item.id,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.$message({
                message: res.data.message,
                type: "success",
              });
              this.handleAdd_NO();
              this.again_detail();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleClose_AddArrival() {
      //关闭新增到账
      this.again_detail();
      this.isAddArrival = false;
      this.isMore = false;
    },
    closeMap(i, obj, keyword) {
      //地图
      if (i == 1) {
        this.longitude = obj.lng;
        this.latitude = obj.lat;
        this.company_address = keyword;
      }
      this.is_map = false;
    },
    handleAdd() {
      //确定编辑
      if (this.add_flag) {
        return;
      }
      let company_add_time = "";
      if (this.time != "") {
        company_add_time = this.getwaytime(this.time); //成立时间
      } else {
        company_add_time = "";
      }
      let {
        registered_capital,
        website,
        management_product,
        wechat,
        company_name,
        contact,
        phone,
        city_name_id,
        company_address,
        position,
        province_name,
        city_name,
        area_name,
        source,
        my_customer_id,
        longitude,
        latitude,
        img_list,
      } = this;
      var partten = /^1[3,4,6,9,5,8,7]\d{9}$/;
      if (!partten.test(phone)) {
        this.$message({
          type: "error",
          message: "电话填写错误，请检查后输入",
        });
        return;
      }
      this.add_flag = true;
      let data = {
        name: company_name, //公司名称
        contacts: contact, //联系人单个
        phone, //电话
        position_id: position, //职位id
        province_name,
        city_name,
        area_name,
        province_id: city_name_id[0],
        city_id: city_name_id[1],
        area_id: city_name_id[2],
        address: company_address, //详细地址
        longitude, //经度
        latitude, //维度
        management_product, //客户情况
        wechat: img_list.length > 0 ? JSON.stringify(img_list) : "",
        customer_source: source, //客户来源
        company_add_time, //成立时间
        registered_capital,
        website,
        my_customer_id,
      };
      editCustomer(data).then((res) => {
        this.add_flag = false;
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.handleClose();
          this.again_detail();
        }
      });
    },
    //客户标星
    onXing() {
      myClientsStar({
        my_customer_id: this.my_customer_id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.again_detail();
        }
      });
    },
    //白名单
    onWhileList() {
      myClientWhite({
        my_customer_id: this.my_customer_id,
      }).then((res) => {
        console.log("onWhileList", res);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.customerdetail.is_white_list = res.data.data.is_white_list;
          this.$forceUpdate();
        }
      });
    },
    handleClose() {
      this.isMore = false;
      this.isEdit = false;
      this.registered_capital = "";
      this.website = "";
      this.management_product = "";
      this.wechat = "";
      this.company_name = "";
      this.contact = "";
      this.phone = "";
      this.city_name_id = [];
      this.company_address = null;
      this.position = null;
      this.province_name = "";
      this.city_name = "";
      this.area_name = "";
      this.source = "";
    },
    hanldeAdd_OK() {
      if (this.add_flag) {
        return;
      }
      let { add_what } = this;
      if (add_what == 1) {
        //新增联系人
        let { add_contact, add_phone, position_id, my_customer_id } = this;
        var partten = /^1[3,4,6,9,5,8,7]\d{9}$/;
        if (!partten.test(add_phone)) {
          this.$message({
            type: "error",
            message: "电话填写错误，请检查后输入",
          });
          return;
        }
        let obj = {
          contacts: add_contact,
          phone: add_phone,
          position_id,
        };
        let arr = [];
        let isadd = false;
        arr.push(obj);
        arr.forEach((item) => {
          if (item.contacts == "" || item.phone == "" || item.position_id == "") {
            this.$message({
              message: "请完善联系人数据",
              type: "error",
            });
            isadd = true;
            return;
          }
        });
        if (isadd) {
          return;
        }
        this.add_flag = true;
        addContacts({
          other_contacts: JSON.stringify(arr),
          my_customer_id,
        }).then((res) => {
          this.add_flag = false;
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.handleAdd_NO();
            this.again_detail();
          }
        });
      }
      if (add_what == 2) {
        let { product_name, customer_analysis, my_customer_id } = this;
        let data = {
          my_customer_id,
          customer_product_id: product_name[1],
          customer_analysis,
          customer_id: this.customerdetail.customer_id,
        };
        let ispro = false;
        if (data.my_customer_id == "" || data.customer_product_id == "" || data.customer_id == "") {
          this.$message({
            message: "请完善数据",
            type: "error",
          });
          ispro = true;
          return;
        }
        if (ispro) {
          return;
        }
        this.add_flag = true;
        addProduct(data).then((res) => {
          this.add_flag = false;
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.handleAdd_NO();
            this.again_detail();
          }
        });
      }
      if (add_what == 8) {
        var products = this.my_customer_product.map((item) => {
          return {
            customer_product_id: item.id,
            customer_analysis: item.customer_analysis,
          };
        });
        console.log(this.my_customer_product);
        editProducts({
          my_customer_id: this.my_customer_product[0].my_customer_id,
          products: JSON.stringify(products),
        }).then((res) => {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.handleAdd_NO();
          this.again_detail();
        });
      }
    },
    handle_apply() {
      //确定申请促销
      if (this.add_flag) {
        return;
      }
      let {
        my_customer_id,
        apply_money,
        apply_explain,
        cx_proname_id,
        cx_typeid,
        customerdetail,
        cx_zc_typeid,
        time_section,
        service_info,
      } = this;
      if (isNaN(Number(apply_money))) {
        this.$message.error("请输入正确的促销成本金额");
        return;
      }
      if (cx_typeid == 4) {
        if (time_section.length == 0) {
          this.$message.error("请选择服务时长区间");
          return;
        }
      }
      let start_service_duration = "";
      let end_service_duration = "";
      let other_service_detail = "";
      if (cx_typeid == 4) {
        start_service_duration = this.getwaytime(time_section[0]);
        end_service_duration = this.getwaytime(time_section[1]);
      } else if (cx_typeid == 5) {
        other_service_detail = service_info;
      } else {
        other_service_detail = "";
        start_service_duration = "";
        end_service_duration = "";
      }
      if (cx_typeid == 5) {
        if (service_info == "") {
          this.$message.error("请输入服务详情");
          return;
        }
      }
      this.add_flag = true;
      let data = {
        type: cx_typeid,
        money: apply_money,
        apply_explain,
        my_customer_id,
        product_name_id: cx_proname_id,
        customer_id: customerdetail.customer_id,
        other_service_detail,
        start_service_duration,
        end_service_duration,
        policy_type: cx_zc_typeid,
      };
      for (const i in data) {
        if (i !== "end_service_duration" && i !== "start_service_duration" && i !== "other_service_detail") {
          if (data[i] == "") {
            this.add_flag = false;
            this.$message.error("请填写完整内容后提交");
            return;
          }
        }
      }
      addPromotion(data).then((res) => {
        this.add_flag = false;
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.handleclose_Apply();
        }
      });
    },
    handleChange(e, i) {
      let { cityList } = this;
      if (i == 1) {
        //城市
        cityList.forEach((item) => {
          if (item.id == e[0]) {
            this.province_name = item.name;
          }
        });
      }
    },
    handleclose_Apply() {
      //取消促销
      this.cx_prooption = [];
      this.apply_money = "";
      this.apply_explain = "";
      this.cx_proname_id = "";
      this.isMore = false;
      this.cx_typeid = "";
      this.is_CX = false;
    },
    again_detail() {
      let { type } = this;
      detail({
        customer_id: this.customerdetail.customer_id,
        type,
        my_customer_id: this.my_customer_id,
      }).then((res) => {
        if (res.data.code == 200) {
          if (type == 1) {
            this.customerdetail = res.data.data;
            this.customer_contacts = res.data.data.customer_contacts_list;
            this.my_customer_product = res.data.data.my_customer_product;
          }
          if (type == 2) {
            this.customerdetail = res.data.data[0];
            let list = res.data.data;
            list.forEach((item) => {
              this.positionList.forEach((itm) => {
                if (item.customer_contacts.position_id == itm.id) {
                  item.customer_contacts.position_name = itm.name;
                }
              });
            });
            this.departmentList = list;
          }
        }
      });
    },
    sent_new_plan() {
      //新增计划
      if (this.add_flag) {
        return;
      }
      let { my_customer_id, customerdetail, flow_kind, check_time, flow_plan } = this;
      let arr = [];
      customerdetail.my_customer_product.forEach((item) => {
        let obj = {
          product_name_id: item.product_name_id,
          // intention: item.intention,
        };
        arr.push(obj);
      });
      if (check_time == "" || flow_kind == "" || flow_plan == "") {
        this.$message({
          message: "请完善内容",
          type: "error",
        });
        return;
      }
      this.add_flag = true;

      let data = {
        my_customer_id,
        plan: flow_plan,
        status: flow_kind,
        to_time: this.getwaytime_s(check_time),
        product_ids: JSON.stringify(arr),
        follow_type: this.my_customer_service_id ? 2 : 1,
      };
      addPlan(data).then((res) => {
        this.add_flag = false;
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.isMore = false;
          this.show_flag = false;
          let { isCheck } = this;
          if (isCheck == 2) {
            let data = {};
            if (this.my_customer_service_id) {
              data = {
                my_customer_id: this.my_customer_id,
                follow_type: 2,
              };
            } else {
              data = {
                my_customer_id: this.my_customer_id,
              };
            }
            followList(data).then((res) => {
              let list = res.data.data.data;
              this.follow_List = list;
            });
          }
        }
      });
    },
    handleAdd_NO() {
      this.add_contact = "";
      this.add_phone = "";
      this.position_id = "";
      this.product_name = "";
      // this.intention = "";
      this.customer_analysis = "";
      this.isMore = false;
      this.isAddcontact = false;
    },
    //跟进结果
    handleFillPlan(item) {
      (this.plan_end = ""), //跟进结果
        (this.img_list = []),
        (this.info_detail = null),
        (this.imgsrc = ""), // 放大图片路径
        (this.big_imgshow = false), //放大图片显示隐藏
        (this.flow_time = ""), //跟进时间
        (this.is_tegether = "2"), //是否配方人
        (this.flow_human = ""), //跟进
        (this.tegether_name = ""), //配方人姓名
        (this.objective = ""),
        (this.address = "");
      this.info_detail = item;
      // this.isMore = true;
      this.notes_show = true;
    },
    del_img(i) {
      this.img_list.splice(i, 1);
    },
    take_big(src) {
      this.imgsrc = src;
      this.big_imgshow = true;
    },
    chooseFile: function () {
      this.$refs.choosePhoto.addEventListener("change", this.photoChange);
      this.$refs.choosePhoto.dispatchEvent(new MouseEvent("click"));
    },
    photoChange(el) {
      var file = el.target.files[0]; //name: "dangqi1.png" || type: "image/png"
      var type = file.type.split("/")[0];
      if (type === "image") {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var that = this;
        reader.onloadend = function () {
          var dataURL = reader.result;
          var blob = that.dataURItoBlob(dataURL);
          that.upload(blob); //执行上传接口
        };
      } else {
        this.$message.error("请上传图片哦~~");
      }
    },
    sent_flow() {
      if (this.add_flag) {
        return;
      }
      let { info_detail } = this;
      let trail_time = "";
      let trail_end_time = "";
      if (this.flow_time) {
        trail_time = this.getwaytime_s(this.flow_time[0]);
        trail_end_time = this.getwaytime_s(this.flow_time[1]);
      }
      let file_url = [];
      for (let i in this.img_list) {
        file_url.push({ file_url: this.img_list[i].file_url });
      }
      file_url = JSON.stringify(file_url);
      let data = {
        my_customer_id: info_detail.my_customer_id,
        customer_plan_id: info_detail.id,
        status: info_detail.status,
        result: this.plan_end,
        trail_time,
        trail_end_time,
        file_url,
        visit_status: this.is_tegether,
        visit_people: this.flow_human,
        purpose: this.objective, // purpose
        visit_name: this.tegether_name,
        address: this.address,
      };
      this.add_flag = false;
      addPlanLog(data).then((res) => {
        this.add_flag = false;
        if (res.data.code == 200) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.isFollow = true;
          this.notes_show = false;
          if (this.my_customer_service_id) {
            data = {
              my_customer_id: this.my_customer_id,
              follow_type: 2,
            };
          } else {
            data = {
              my_customer_id: this.my_customer_id,
            };
          }
          followList(data).then((res) => {
            let list = res.data.data.data;
            this.follow_List = list;
          });
        }
      });
    },
    dataURItoBlob(dataURI) {
      // base64 解码
      let byteString = window.atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let T = mimeString.split("/")[1];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    upload(imgUrl) {
      let formData = new FormData();
      this.$message({
        message: "上传中，请稍后",
      });
      formData.append("file", imgUrl);
      uploadFile(formData).then((res) => {
        if (res.data.code == 200) {
          this.img_list.push({ file_url: res.data.data.file_url });
          this.$message({
            message: "上传成功",
            type: "success",
          });
        } // console.log(res);
      });
    },
    // 查看部门跟进记录
    handledepartmentfollow(val) {
      this.$router.push({
        name: "departmentfollow",
        params: val,
      });
    },
    //业绩详情
    todetail(item) {
      setTimeout(() => {
        this.$forceUpdate();
      }, 10);
      this.$router.push({
        name: "orderdetail",
        params: {
          class_type: this.type,
          order_id: item.id,
        },
      });
    },
    //    下单状态
    placeOrder(row) {
      this.order_id = row.id;
      this.radio = row.type.toString();
      this.dialogVisible = true;
    },
    dialogOk() {
      editOrdertype({
        order_id: this.order_id,
        type: this.radio,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getList(this.type);
        }
      });
    },
    show_commen(item) {
      this.commen_show = true;
      this.row = item;
      this.commen = "";
    },
    sent_commen() {
      if (this.add_flag == true) {
        return;
      }
      this.add_flag = true;
      addComment({
        permissions_id_string: this.permissions_id_string,
        my_customer_log_id: this.row.id,
        comment: this.commen,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "促进成功",
              type: "success",
            });
            setTimeout(() => {
              this.add_flag = false;
            }, 100);
            this.commen_show = false;
            this.commen = "";
            let dataD = {};
            if (this.my_customer_service_id) {
              dataD = {
                my_customer_id: this.my_customer_id,
                follow_type: 2,
              };
            } else {
              dataD = {
                my_customer_id: this.my_customer_id,
              };
            }
            followList(dataD).then((res) => {
              let listS = res.data.data.data;
              this.follow_List = listS;
            });
          }
        })
        .catch(() => {
          setTimeout(() => {
            this.add_flag = false;
          }, 100);
        });
    },
  },
};
</script>

<style lang="scss">
.cutomer_detail {
  margin: 20px;
  box-sizing: border-box;
  width: 100%;

  .login_mc {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
  }

  .el-main {
    .cutomer_detail_box {
      padding: 20px 30px;
      background: #fff;
      border-radius: 4px;
      margin-bottom: 16px;

      .cutomer_detail_head {
        height: 35px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cutomer_detail_head_name {
          font-family: Microsoft YaHei;
          line-height: 21px;
          opacity: 1;

          h1 {
            font-size: 18px;
            font-weight: bold;
            color: #595959;
          }
        }

        .cutomer_detail_back {
          width: 90px;
          height: 35px;
          background: #3a85fc;
          border-radius: 2px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .cutomer_detail_info {
        display: flex;
        box-sizing: border-box;
        padding: 20px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);

        div:first-child {
          padding-left: 0;
        }

        div {
          padding: 0 29px;
          white-space: nowrap;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
        }

        div:last-child {
          border-right: 0;
        }
      }

      .cutomer_detail_info_cont {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .prod-item {
          width: 33%;
          flex: auto;
          margin-bottom: 15px;
        }

        div {
          flex-direction: column;
          display: flex;
          align-items: flex-start;
          flex: 1;

          p {
            font-size: 14px;
            color: #999;
            margin-top: 14px;

            span {
              color: #595959;
            }
          }
        }
      }

      .cutomer_detail_button {
        display: flex;
        align-items: center;
        margin-left: 32px;
        /*padding-top: 22px;*/
        div {
          display: flex;
          height: 35px;
          align-items: center;
          cursor: pointer;
          padding: 0 15px;
          border-radius: 4px;
          margin-right: 20px;
          font-size: 14px;
          border: 1px solid #3a85fc;
          color: #3a85fc;

          img {
            margin-right: 5px;
          }
        }

        .img_check {
          background: #3a85fc;
          color: #ffffff;
          border: 1px solid #ffff;
        }
      }

      .cutomer_detail_box_head {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10196078431372549);

        .cutomer_detail_box_head_check {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #3a85fc;
          opacity: 1;
          display: flex;
          width: 65px;
          flex-direction: column;
          align-items: center;
          height: 35px;
          justify-content: space-between;

          div:last-child {
            width: 65px;
            height: 3px;
            background: #3a85fc;
            opacity: 1;
          }
        }

        .cutomer_detail_box_head_no_check {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.48);
          opacity: 1;
          width: 65px;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 35px;
          justify-content: space-between;

          div:last-child {
            width: 65px;
            height: 3px;
            background: #fff;
            opacity: 1;
          }
        }
      }

      .cutomer_detail_box_information {
        .cutomer_detail_box_information_head {
          margin: 28px 0 22px 0;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;

          img {
            margin-right: 16px;
          }
        }

        .cutomer_detail_box_information_table {
          .cutomer_detail_box_information_table_line {
            height: 60px;
            border: 1px solid rgba(0, 0, 0, 0.10196078431372549);
            // border-bottom: 1px solid rgba(0, 0, 0, 0.10196078431372549) ;
            display: flex;
            align-items: center;

            .cutomer_detail_box_information_table_line_one {
              display: flex;
              width: 50%;
              height: 100%;
              align-items: center;

              .cutomer_detail_box_information_table_line_tit {
                width: 220px;
                height: 100%;
                background: #fafafa;
                display: flex;
                align-items: center;
                border-right: 1px solid rgba(0, 0, 0, 0.10196078431372549);
              }

              div {
                padding-left: 20px;
                box-sizing: border-box;
              }

              .cutomer_detail_box_information_table_line_info {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 19px;
                color: rgba(0, 0, 0, 0.8);
                opacity: 1;
                flex: 1;
                height: 100%;
                display: flex;
                align-items: center;
                border-right: 1px solid rgba(0, 0, 0, 0.10196078431372549);
              }
            }
          }

          .cutomer_detail_box_information_table_line:last-child {
            border-top: none;

            .cutomer_detail_box_information_table_line_info {
              border-right: none;
            }
          }

          .cutomer_detail_box_information_table_cantact_head {
            display: flex;
            height: 60px;
            align-items: center;
            background: #fafafa;
            border: 1px solid #ccc;

            div {
              width: 33%;
              box-sizing: border-box;
              padding-left: 20px;
              height: 100%;
              display: flex;
              align-items: center;
            }

            div:nth-child(2) {
              border-right: 1px solid rgba(0, 0, 0, 0.10196078431372549);
              border-left: 1px solid rgba(0, 0, 0, 0.10196078431372549);
            }
          }

          .cutomer_detail_box_information_table_cantact {
            display: flex;
            height: 60px;
            align-items: center;
            border: 1px solid #ccc;
            border-top: none;

            div {
              width: 33%;
              box-sizing: border-box;
              padding-left: 20px;
              height: 100%;
              display: flex;
              align-items: center;
            }

            div:nth-child(2) {
              border-right: 1px solid #ccc;
              border-left: 1px solid #ccc;
            }
          }

          .cutomer_detail_box_information_table_head {
            display: flex;
            height: 60px;
            align-items: center;
            background: #fafafa;
            border: 1px solid #ccc;

            div {
              width: 25%;
              padding-left: 20px;
              height: 100%;
              align-items: center;
              display: flex;
            }

            div:nth-child(2) {
              border-right: 1px solid rgba(0, 0, 0, 0.10196078431372549);
              border-left: 1px solid rgba(0, 0, 0, 0.10196078431372549);
            }

            div:nth-child(3) {
              border-right: 1px solid rgba(0, 0, 0, 0.10196078431372549);
              // border-left: 1px solid rgba(0, 0, 0, 0.10196078431372549);
            }
          }

          .cutomer_detail_box_information_table_pro {
            display: flex;
            height: 60px;
            align-items: center;
            border: 1px solid #ccc;
            border-top: none;

            div {
              width: 25%;
              padding-left: 20px;
              height: 100%;
              align-items: center;
              display: flex;
            }

            div:nth-child(2) {
              border-right: 1px solid rgba(0, 0, 0, 0.10196078431372549);
              border-left: 1px solid rgba(0, 0, 0, 0.10196078431372549);
            }

            div:nth-child(3) {
              border-right: 1px solid rgba(0, 0, 0, 0.10196078431372549);
              // border-left: 1px solid rgba(0, 0, 0, 0.10196078431372549);
            }
          }
        }

        .cutomer_detail_box_information_box {
          margin-top: 50px;
          background: #fff;

          .cutomer_detail_box_information_type_head {
            height: 40px;
            padding: 0 30px;
            margin: 30px 0;
            background: #fafafa;
            display: flex;
            align-items: center;

            i {
              display: block;
              width: 2px;
              height: 12px;
              background: #3a85fc;
              opacity: 1;
              margin-right: 10px;
            }
          }

          .cutomer_detail_box_information_type_line {
            display: flex;
            justify-content: space-between;
            padding: 0 30px;
            box-sizing: border-box;

            i {
              color: #3a85fc;
              cursor: pointer;
              margin-left: 5px;
            }
          }

          .cutomer_detail_box_information_type_table {
            padding: 0 30px;
            width: 100%;
          }
        }
      }

      .cutomer_detail_box_Follow {
        .cutomer_detail_box_Follow_none {
          color: #ccc;
          margin: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .Follow_tit {
          padding: 0 30px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
          display: flex;
          align-items: center;
          margin: 20px 0;

          i {
            display: block;
            width: 6px;
            height: 6px;
            background: #3a85fc;
            border-radius: 50%;
            opacity: 1;
            margin-right: 10px;
          }
        }

        .Follow_none {
          color: #ccc;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .Follow_customer_info {
          padding: 0px 40px;

          .Follow_customer_info_box {
            background: #f8f8f8;
            padding: 0 40px;
            display: flex;
            flex-direction: column;
            height: 60px;
            justify-content: space-around;

            .Follow_customer_info_box_line {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.85);
              justify-content: space-between;

              .Follow_customer_info_box_line_one {
                display: flex;

                .Follow_customer_info_box_line_one_tit {
                  color: rgba(0, 0, 0, 0.5);
                }

                .Follow_customer_info_box_line_one_info {
                  color: rgba(0, 0, 0, 0.8);
                }
              }
            }
          }

          .Follow_customer_info_plan {
            background: #fff;
            padding: 0 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            position: relative;

            .Follow_customer_info_plan_bottom {
              position: absolute;
              bottom: -30px;
              right: 20px;
              width: 80px;
              height: 35px;
              background: #3a85fc;
              opacity: 1;
              border-radius: 4px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: #ffffff;
              opacity: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .Follow_customer_info_plan_line {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.85);
              // justify-content: space-between;
              width: 100%;
              margin: 10px 0;

              .Follow_customer_info_plan_line_one {
                display: flex;

                .Follow_customer_info_plan_line_one_tit {
                  color: rgba(0, 0, 0, 0.5);
                  white-space: nowrap;
                }

                .Follow_customer_info_plan_line_one_info {
                  color: rgba(0, 0, 0, 0.8);
                  white-space: nowrap;
                  flex: 1;
                }

                .Follow_customer_info_plan_line_one_img {
                  display: flex;

                  img {
                    width: 60px;
                    height: 60px;
                    margin-right: 20px;
                  }
                }

                .Follow_customer_info_plan_line_one_time {
                  width: 66px;
                  height: 20px;
                  background: rgba(58, 133, 252, 0.1);
                  opacity: 1;
                  border-radius: 20px;
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  line-height: 16px;
                  color: #3a85fc;
                  opacity: 1;
                  margin-left: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .personal_result {
                  display: flex;
                  width: 80px;
                  height: 40px;
                  align-items: center;
                  justify-content: center;
                  background: #3a85fc;
                  color: #fff;
                  font-size: 14px;
                  border-radius: 4px;
                  cursor: pointer;
                }
              }

              .Follow_customer_info_plan_line_one:nth-child(2) {
                margin: 0 20%;
              }
            }
          }
        }
      }
    }
  }

  .add_box {
    height: 50%;
    min-height: 414px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #fff;
    margin: auto;
    border-radius: 10px;
    width: 560px;

    .add_box_head {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      i {
        padding: 10px;
        cursor: pointer;
        position: absolute;
        right: 20px;
      }
    }

    .add_box_head_tit {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.6);
      opacity: 1;
      height: 20px;
      display: flex;
      align-items: center;
      padding: 0 33px;
      box-sizing: border-box;
      margin: 20px 0;

      i {
        width: 6px;
        height: 6px;
        background: #3a85fc;
        border-radius: 50%;
        opacity: 1;
        margin-right: 10px;
      }
    }

    .add_box_info {
      padding: 0 100px;
      box-sizing: border-box;

      .add_box_info_line {
        display: flex;
        height: 32px;
        align-items: center;
        margin: 20px 0;

        .add_box_info_line_tit {
          width: 70px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .add_box_info_line_box {
          flex: 1;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 2px;
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 15px;
        }

        .el-input {
          height: 100%;
          flex: 1;

          input {
            height: 100%;
          }
        }

        .el-select {
          height: 100%;
          flex: 1;

          input {
            height: 100%;
          }

          .el-input__suffix {
            display: flex;
            align-items: center;
          }
        }

        .el-cascader {
          height: 100%;
          flex: 1;

          input {
            height: 100%;
          }

          .el-input__suffix-inner {
            display: flex;
            align-items: center;
          }
        }

        .el-textarea {
          flex: 1;

          .el-textarea__inner {
            //el_input中的隐藏属性
            resize: none; //主要是这个样式
          }
        }
      }
    }

    .add_box_bottom {
      height: 40px;
      display: flex;
      justify-content: flex-end;
      margin-top: 50px;
      padding-right: 40px;
      position: absolute;
      bottom: 40px;
      right: 40px;

      div {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        font-size: 14px;
        margin-left: 20px;
        border-radius: 4px;
      }

      div:first-child {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
        color: rgba(0, 0, 0, 0.5);
      }

      div:last-child {
        background: #3a85fc;
        color: #fff;
      }
    }
  }

  .edit_box_big {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .edit_box {
      z-index: 99;
      // width: 40%;
      height: 80%;
      width: 650px;
      background: #ffff;
      position: relative;
      margin: auto;
      margin-top: 50px;
      border-radius: 10px;
      padding-bottom: 30px;
      overflow: hidden;

      .add_cutomer_head {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.8);
        opacity: 1;
        border: 1px solid rgba(0, 0, 0, 0.2);
        position: relative;

        i {
          position: absolute;
          right: 20px;
        }
      }

      .add_cutomer_box {
        box-sizing: border-box;
        padding: 30px;
        height: 97%;
        overflow: auto;

        .add_cutomer_box_head {
          display: flex;
          align-items: center;

          i {
            width: 6px;
            height: 6px;
            background: #3a85fc;
            border-radius: 50%;
            opacity: 1;
            margin-right: 7px;
          }
        }

        .add_cutomer_box_info {
          padding-left: 60px;
          padding-right: 70px;
          display: flex;
          align-items: center;
          min-height: 32px;
          margin: 20px 0;

          .add_cutomer_box_info_tit {
            width: 80px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            i {
              display: flex;
              height: 10px;
              width: 10px;
              align-items: center;
              justify-content: center;
              color: red;
            }
          }

          .el-input {
            flex: 1;
            height: 32px;

            input {
              height: 32px;
            }

            .el-input__prefix {
              .el-icon-date {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }

          .el-select {
            flex: 1;

            .el-input__suffix {
              display: flex;
              align-items: center;
            }
          }

          .el-textarea {
            flex: 1;

            .el-textarea__inner {
              //el_input中的隐藏属性
              resize: none; //主要是这个样式
            }
          }

          .el-cascader {
            flex: 1;
          }

          .el-date-editor {
            flex: 1;
            height: 100%;

            input {
              height: 100%;
              padding-left: 15px;
            }

            .el-input__prefix {
              left: initial;
              right: 5px;
              display: flex;
              align-items: center;

              .el-input__icon {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            &.date-close {
              .el-icon-date {
                display: none;
              }
            }
          }
        }

        .other_contact {
          flex: 1;
          height: 32px;
          display: flex;
          align-items: center;
          padding-left: 15px;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
        }

        .add_more_contacts {
          margin: 0 70px 0 140px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          /*border: 1px dashed rgba(0, 0, 0, 0.2);*/
          opacity: 1;
          border-radius: 2px;
        }

        .add_cutomer_box_bottom {
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: flex-end;
          margin-top: 50px;
          // position: absolute;
          // bottom: 50px;
          // right: 80px;
          div {
            width: 80px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            opacity: 1;
            cursor: pointer;
          }

          div:first-child {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
            opacity: 1;
            color: rgba(0, 0, 0, 0.5);
          }

          div:last-child {
            background: #3a85fc;
            opacity: 1;
            color: #fff;
            margin-left: 20px;
          }
        }
      }
    }
  }

  .add_cx_bigbox {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .add_cx_box {
      // width: 40%;
      width: 650px;
      height: 80%;
      position: relative;
      z-index: 99;
      background: #fff;
      margin: auto;
      border-radius: 10px;
      min-width: 560px;
      margin-top: 50px;
      overflow: hidden;

      .add_box_head {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        i {
          padding: 10px;
          cursor: pointer;
          position: absolute;
          right: 20px;
        }
      }

      .add_cx_box_info {
        height: 90%;
        overflow: auto;

        .add_cx_box_head {
          margin: 15px 20px;
          height: 40px;
          background: #fafafa;
          opacity: 1;
          padding-left: 32px;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.8);
          opacity: 1;

          i {
            width: 2px;
            height: 12px;
            background: #3a85fc;
            opacity: 1;
            display: block;
            margin-right: 5px;
          }
        }

        .add_cx_box_customer {
          padding: 0 70px;

          .add_cx_box_customer_line {
            display: flex;
            height: 32px;
            align-items: center;
            margin-bottom: 24px;

            .add_cx_box_customer_line_tit {
              width: 70px;
            }

            .el-date-editor {
              height: 32px;
              line-height: 32px;
              flex: 1;
              display: flex;
              align-items: center;

              .sdal {
                width: 0;
              }

              .el-range-separator {
                height: auto;
              }

              input {
                width: 50%;
              }

              .el-input__icon {
                height: auto;
              }
            }

            .add_cx_box_customer_line_img {
              display: flex;
              align-items: center;
              flex: 1;
              justify-content: flex-end;

              img {
                width: 40px;
                height: 40px;
                border-radius: 4px;
              }

              i {
                margin: 0 10px;
              }
            }

            .add_cx_box_customer_line_int {
              flex: 1;
              height: 100%;
              padding-left: 15px;
              border: 1px solid #dcdfe6;
              border-radius: 4px;
              display: flex;
              align-items: center;
            }

            .el-select {
              height: 100%;
              flex: 1;

              .el-input__suffix {
                display: flex;
                align-items: center;
              }
            }

            .el-textarea {
              flex: 1;

              .el-textarea__inner {
                //el_input中的隐藏属性
                resize: none; //主要是这个样式
              }
            }

            .el-input {
              height: 100%;
              flex: 1;

              input {
                height: 100%;
              }
            }
          }
        }

        .add_cx_box_bottom {
          display: flex;
          justify-content: flex-end;
          right: 50px;
          margin-top: 50px;
          margin-bottom: 30px;
          margin-right: 30px;

          div {
            height: 40px;
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            border-radius: 4px;
            cursor: pointer;
          }

          div:first-child {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.30196078431372547);
            color: rgba(0, 0, 0, 0.5);
          }

          div:last-child {
            background: #3a85fc;
            color: #fff;
            margin-left: 20px;
          }
        }
      }
    }
  }

  //新增计划
  .info_add {
    width: 700px;
    height: 540px;
    margin: auto;
    background: #ffffff;
    border: 1px solid #707070;
    opacity: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    border-radius: 5px;
    // padding-top: 66px;
    box-sizing: border-box;

    .take_new_title {
      // top: -66px;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 66px;
      position: relative;
      border-bottom: 1px solid #ccc;
      text-align: center;
      line-height: 66px;
      font-size: 18px;

      i {
        position: absolute;
        top: 23px;
        right: 23px;
      }
    }

    .take_new_detail {
      width: 100%;
      box-sizing: border-box;
      overflow: auto;
      padding: 20px;

      .acc_top_compony {
        .kf_detail {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .quan {
            height: 5px;
            width: 5px;
            border-radius: 2.5px;
            background: #3a85fc;
            margin-right: 6px;
          }
        }

        .kh_detail {
          padding: 20px;
          width: 100%;
          box-sizing: border-box;
          height: 80px;
          margin-top: 10px;
          display: inline-block;
          background: #f8f8f8;

          div {
            overflow: hidden; /*超出部分隐藏*/
            white-space: nowrap; /*不换行*/
            text-overflow: ellipsis; /*超出部分文字以...显示*/
            width: 50%;
            display: inline-block;
            height: 50%;
          }
        }
      }
    }

    .flow_kind {
      width: 50%;
      display: flex;
      margin-top: 20px;
      align-items: center;

      .el-select {
        width: 250px;
      }

      .el-date-editor.el-input {
        width: 250px;
      }
    }

    .take_bottom {
      height: 40px;
      position: relative;
      bottom: 40px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      box-sizing: border-box;
      padding-right: 40px;
      align-items: center;
      margin-top: 60px;

      .bottom_sent {
        width: 80px;
        height: 40px;
        background: #3a85fc;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        color: #fff;
        border-radius: 4px;
      }
    }

    .flow_kind_text {
      width: 80%;
      display: flex;
      margin-top: 20px;
      align-items: flex-start;
      white-space: nowrap;

      .el-textarea {
        textarea {
          resize: none;
        }
      }
    }
  }

  //填写跟进结果
  .acc_shadow {
    position: fixed;
    z-index: 120;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .el-range-separator {
      width: 30px;
    }

    .info {
      width: 772px;
      // height: 540px;
      margin: 100px auto;
      background: #ffffff;
      border: 1px solid #707070;
      opacity: 1;
      position: relative;
      border-radius: 5px;
      z-index: 999;
      // padding-top: 66px;
      box-sizing: border-box;

      .take_new_title {
        // top: -66px;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 66px;
        position: relative;
        border-bottom: 1px solid #ccc;
        text-align: center;
        line-height: 66px;
        font-size: 18px;

        i {
          position: absolute;
          top: 23px;
          right: 23px;
        }
      }

      .take_new_detail {
        height: 468px;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;
        padding: 20px;

        .acc_top_compony {
          .kf_detail {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .quan {
              height: 5px;
              width: 5px;
              border-radius: 2.5px;
              background: #3a85fc;
              margin-right: 6px;
            }
          }

          .kh_detail {
            padding: 20px;
            width: 100%;
            box-sizing: border-box;
            height: 80px;
            margin-top: 10px;
            display: inline-block;
            background: #f8f8f8;

            div {
              overflow: hidden; /*超出部分隐藏*/
              white-space: nowrap; /*不换行*/
              text-overflow: ellipsis; /*超出部分文字以...显示*/
              width: 50%;
              display: inline-block;
              height: 50%;
            }
          }
        }
      }

      .img_item_info {
        width: 100px;
        height: 100px;
        position: relative;
        margin-right: 10px;

        .big_img {
          width: 100%;
        }

        .del_img {
          position: absolute;
          top: -5px;
          right: -5px;
          color: #ccc;
        }
      }

      .image_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 4px;
        width: 100px;
        height: 100px;
        position: relative;
        background: #ececec;
        color: rgba(0, 0, 0, 0.2);
        justify-content: center;
      }

      .flow_img_listinfo {
        display: flex;
      }

      .flow_kind {
        width: 50%;
        display: flex;
        margin-top: 20px;
        white-space: nowrap; /*不换行*/
        align-items: center;

        .el-select {
          width: 250px;
        }

        .el-date-editor .el-range-input {
          overflow: hidden; /*超出部分隐藏*/
          white-space: nowrap; /*不换行*/
          text-overflow: ellipsis; /*超出部分文字以...显示*/
        }

        .el-date-editor.el-input {
          width: 250px;
        }
      }

      .take_bottom {
        height: 66px;
        position: relative;
        bottom: 40px;
        width: 100%;
        display: flex;
        margin-top: 60px;
        // align-items: center;
        // justify-content: center;
        box-sizing: border-box;
        justify-content: flex-end;
        padding-right: 40px;
        align-items: center;

        .bottom_sent {
          width: 80px;
          height: 40px;
          background: #3a85fc;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          color: #fff;
          border-radius: 4px;
        }
      }

      .flow_kind_text {
        width: 80%;
        display: flex;
        margin-top: 20px;
        padding-top: 1px;
        // overflow:hidden;
        // text-overflow:ellipsis;
        // white-space:nowrap;
        .el-textarea {
          textarea {
            resize: none;
          }
        }

        .el-dialog__wrapper {
          z-index: 2010 !important;
        }
      }
    }
  }

  //图片放大
  .acc_img_shadow {
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    height: 100%;

    img {
      display: block;
      width: 500px;
      margin: 200px auto;
      max-height: 600px;
    }
  }
}

.cutomer_detail_box h1 {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 31px;
  color: #595959;
  opacity: 1;
}

.cutomer_detail_Statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 350px;
    height: 213px;
    background: #f5f8fd;
    border: 1px solid #f5f5f5;
    opacity: 1;
    border-radius: 10px;
    padding: 30px;
    margin-top: 26px;

    h2 {
      color: #595959;
    }

    p {
      font-size: 14px;
      color: #999999;
      margin-top: 16px;
    }
  }
}

.dels {
  min-height: 300px;
  max-height: 400px;
  overflow-y: scroll;
}

/*::-webkit-scrollbar {*/
/*!*隐藏滚轮*!*/
/*display: none;*/
/*}*/

.op-btns {
  & > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 64px;
      height: 64px;
      object-fit: contain;
    }
  }
}

.gray {
  filter: grayscale(100%);
}
.th {
  border-top: 1px solid #999;
}
.th,
.tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .td {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    flex: 1;
    border-right: 1px solid #999;
    border-bottom: 1px solid #999;
    &:nth-child(1) {
      border-left: 1px solid #999;
    }
  }
}
.acc_commen {
  width: 445px;
  height: 426px;
  background: #ffffff;
  border: 1px solid #707070;
  opacity: 1;
  border-radius: 10px;
  margin: 100px auto;
  position: relative;
  .acc_commen_title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    position: relative;
    height: 40px;
    border-bottom: 1px solid #ccc;
    i {
      position: absolute;
      // top: ;
      right: 10px;
    }
  }
  .acc_commen_body {
    width: 100%;
    resize: none;
    padding: 10% 10%;
    .el-textarea__inner {
      resize: none;
      height: 270px;
    }
  }
  .acc_commen_sent {
    position: absolute;
    right: 10px;
    padding: 0 10px;
    height: 25px;
    line-height: 25px;
    bottom: 10px;
    background: #3a85fc;
    color: #fff;
    border-radius: 5px;
  }
}
</style>
