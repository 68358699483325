import request from '@/utils/axios.tool';

//客户列表统计
export const listTotal = (data)=>{              
    return request.post(`/roleapi/customerWeb/listTotal`,data)
}
//客户列表
export const listCustmer = (data)=>{              
    return request.post(`/roleapi/customerWeb/list`,data)
}


//添加客户时搜索客户
export const lookupCustomer = (data)=>{              
    return request.post(`/roleapi/customerWeb/lookupCustomer`,data)
}


//新增 获取职位
export const getPosition = (data)=>{              
    return request.post(`/roleapi/customerWeb/getPosition`,data)
}

//新增 获取产品
export const getProduct = (data)=>{              
    return request.post(`/roleapi/customerWeb/getProduct`,data)
}


//新增 获取省市区
export const getCity = (data)=>{              
    return request.post(`/roleapi/customerWeb/getCity`,data)
}
export const getCityList = (data)=>{              
    return request.post(`/roleapi/customerWeb/getCityList`,data)
}
//新增客户
export const add = (data)=>{              
    return request.post(`/roleapi/customerWeb/add`,data)
}


//客户列表筛选获取产品
export const getProductList = (data)=>{              
    return request.post(`/roleapi/customerWeb/getProductList`,data)
}

//客户信息头部
export const customerHead = (data)=>{              
    return request.post(`/roleapi/customerWeb/customerHead`,data)
}

//客户信息跟进记录
export const followList = (data)=>{              
    return request.post(`/roleapi/planWeb/followList`,data)
}


//客户详情
export const detail = (data)=>{              
    return request.post(`/roleapi/customerWeb/detail`,data)
}


//客户放弃
export const abandon = (data)=>{              
    return request.post(`/roleapi/customerWeb/abandon`,data)
}

//新增联系人
export const addContacts = (data)=>{              
    return request.post(`/roleapi/customerWeb/addContacts`,data)
}
//新增产品
export const addProduct = (data)=>{              
    return request.post(`/roleapi/customerWeb/addProduct`,data)
}


//编辑客户
export const editCustomer = (data)=>{              
    return request.post(`/roleapi/customerWeb/editCustomer`,data)
}

//新增计划
export const addPlan = (data)=>{              
    return request.post(`/roleapi/planWeb/addPlan`,data)
}
//新增计划
export const addResult = (data)=>{
    return request.post(`/roleapi/plan/addResult`,data)
}

//修改计划
export const editPlan = (data)=>{              
    return request.post(`/Applets/plan/editPlan`,data)
}

//促销申请 
export const addPromotion = (data)=>{              
    return request.post(`/roleapi/Promotion/addPromotion`,data)
}


//新增到账
export const addOrder = (data)=>{              
    return request.post(`/roleapi/orderData/addOrder`,data)
}

//判断首签 还是二次到账
export const isAddOrder = (data)=>{
    return request.post(`roleapi/orderData/judgeNewRenew`,data)
}


//新增到账 获取促销单
export const PromotionList = (data)=>{              
    return request.post(`/roleapi/Promotion/PromotionList`,data)
}

//新增到账 获取分单人
export const getUserData = (data)=>{              
    return request.post(`/roleapi/orderData/getUserData`,data)
}


//获取促销申请抄送人
export const getPromotionVerify = (data)=>{              
    return request.post(`/roleapi/Promotion/getPromotionVerify`,data)
}


//当前客户订单列表
export const orderUserList = (data)=>{              
    return request.post(`/roleapi/orderData/orderUserList`,data)
}

//订单详情
export const orderUserDetails = (data)=>{              
    return request.post(`/roleapi/orderData/orderUserDetails`,data)
}

//下单(新增订单)
export const updateOrder = (data)=>{              
    return request.post(`/roleapi/orderData/updateOrder`,data)
}


//添加到账 多次

export const addOrderMore = (data)=>{              
    return request.post(`/roleapi/orderData/addOrderMore`,data)
}

//业绩列表
export const performanceCenter = (data)=>{              
    return request.post(`/roleapi/orderData/performanceCenter`,data)
}

//业绩导出
export const performanceCenterExport = (data)=>{              
    return request.post(`/roleapi/orderData/performanceCenterExport`,data)
}


//业绩详情
export const performanceCenterDetails = (data)=>{              
    return request.post(`/roleapi/orderData/performanceCenterDetails`,data)
}


//成交客户
export const dealList = (data)=>{              
    return request.post(`/roleapi/customerWeb/dealList`,data)
}



//添加跟进结果
export const addPlanLog = (data)=>{              
    return request.post(`/roleapi/planWeb/addPlanLog`,data)
}


//促进
export const addComment = (data)=>{              
    return request.post(`/roleapi/planWeb/addComment`,data)
}


//部门筛选获取部门列表
export const searchCompanysList = (data)=>{              
    return request.post(`/roleapi/customerWeb/searchCompanysList`,data)
}






//我的客服
export const customerServiceList = (data)=>{              
    return request.post(`/roleapi/customerWeb/customerServiceList`,data)
}

//客服信息头部
export const customerServiceHead = (data)=>{              
    return request.post(`/roleapi/customerWeb/customerServiceHead`,data)
}



//意向客户导出

export const listExport = (data)=>{              
    return request.post(`/roleapi/customerWeb/listExport`,data)
}


//成交客户导出
export const dealListExport = (data)=>{              
    return request.post(`/roleapi/customerWeb/dealListExport`,data)
}

//事业部列表
export const selectBusinessCompany = (data)=>{
    return request.get(`/roleapi/publicWater/selectBusinessCompany`,data)
}

//修改产品
export const editProducts = (data)=>{
    return request.post(`/roleapi/customerWeb/editProduct`,data)
}

//放弃产品
export const backLibrary = (data)=>{
    return request.post(`/roleapi/customerWeb/backLibrary`,data)
}

//客户标新
export const myClientsStar = (data)=>{
    return request.post(`/roleapi/customer/myClientsStar`,data)
}


//修改白名单状态
export const myClientWhite = (data)=>{
    return request.post(`/roleapi/customer/myClientWhite`,data)
}

//获取客户标签列表
export const customerListTags = (data)=>{
    return request.post(`/roleapi/customerTag/list`,data)
}

//获取修改客户标签
export const editCustomerTag = (data)=>{
    return request.post(`/roleapi/customerWeb/editCustomerTag`,data)
}

//添加
export const addCustomerListTags = (data)=>{
    return request.post(`/roleapi/customerTag/add`,data)
}
//编辑
export const editCustomerListTags = (data)=>{
    return request.post(`/roleapi/customerTag/edit`,data)
}
//删除客户标签列表
export const delCustomerListTags = (data)=>{
    return request.post(`/roleapi/customerTag/del`,data)
}